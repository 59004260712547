import { useEffect, useState } from 'react'
import {
  Creativeservicev1Creative,
  Campaignservicev1Creative,
} from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'
import { useCampaignContext } from 'Contexts'

export const useGetFullOLCreatives = (
  creatives?: Campaignservicev1Creative[],
  orgId?: string,
) => {
  const [loading, setLoading] = useState(true)
  const [fullCreatives, setFullCreatives] = useState<
    Creativeservicev1Creative[]
  >([])
  const { attachedCreatives } = useCampaignContext()

  useEffect(() => {
    if (creatives && creatives.length > 0) {
      let newCreatives: Creativeservicev1Creative[] = []
      const { creativeServiceAPI } = ApiFactory(
        localStorage.getItem('eltoro_token'),
      )
      type Split = {
        creativeIdsToFetch: string[]
        creativesRetrievedFromCampaignContext: Creativeservicev1Creative[]
      }

      const {
        creativeIdsToFetch,
        creativesRetrievedFromCampaignContext,
      }: Split = creatives.reduce(
        (acc: Split, curr) => {
          // if curr is already in attachedCreatives, get it and put it in second array
          const existing = attachedCreatives.find((c) => c.id === curr.id)
          if (existing) {
            return {
              ...acc,
              creativesRetrievedFromCampaignContext: [
                ...acc.creativesRetrievedFromCampaignContext,
                existing,
              ],
            }
          } else if (curr.id) {
            // otherwise, put the id in the first array
            return {
              ...acc,
              creativeIdsToFetch: [...acc.creativeIdsToFetch, curr.id],
            }
          }
          return acc
        },
        {
          creativeIdsToFetch: [],
          creativesRetrievedFromCampaignContext: [],
        },
      )
      newCreatives = creativesRetrievedFromCampaignContext

      const filter = creativeIdsToFetch.map((id) => `id="${id}"`).join(' OR ')
      if (!filter) {
        setLoading(false)
        setFullCreatives(newCreatives)
        return
      }
      creativeServiceAPI
        ?.advertisingPlatformServiceListCreatives(
          // should probably paginate this if you need them all, cutting off at 100
          creativeIdsToFetch.length > 100 ? 100 : creativeIdsToFetch.length, // pageSize
          undefined, // pageToken
          undefined, // orderBy
          filter,
        )
        .then(({ creatives }) => {
          newCreatives = [
            ...creativesRetrievedFromCampaignContext,
            ...(creatives || []),
          ]
          setFullCreatives(newCreatives)
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
      setFullCreatives([])
    }
  }, [creatives, orgId])

  return [fullCreatives, loading] as const
}
