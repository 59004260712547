import { DependencyList, useReducer, useEffect, useState, useRef } from 'react'

type FetchDataType<T> = ({
  page,
  pageSize,
  nextPageToken,
}: {
  page: number
  pageSize: number
  nextPageToken?: string
}) => Promise<{ data: T[]; totalItems: number; nextPageToken?: string }>

type PaginationProps<T> = {
  pageSize: number
  fetchData: FetchDataType<T>
}

type State<T> = {
  loading: boolean
  currentPageData: T[]
  pageSize: number
  totalItems: number
  totalPages: number
  page: number
  nextPageToken?: string
  fetchData: FetchDataType<T>
}

type Action<T> =
  | { type: 'setPage'; page: number }
  | {
      type: 'setData'
      currentPageData: T[]
      totalItems?: number
      page: number
    }
  | {
      type: 'setNextPageToken'
      nextPageToken?: string
    }
  | { type: 'setPageSize'; pageSize: number; totalPages: number; page: number }
  | { type: 'setLoading'; loading: boolean }

export const useTokenPagination = <T>(
  props: PaginationProps<T>,
  deps?: DependencyList,
) => {
  const [fetch, setRefetch] = useState(false)

  const cache = useRef<{ pageNumber: number; data: T[] }[]>([])

  const reducer = (state: State<T>, action: Action<T>) => {
    switch (action.type) {
      case 'setPage':
        return { ...state, page: action.page }
      case 'setData':
        return {
          ...state,
          currentPageData: action.currentPageData,
          totalItems:
            action.totalItems !== undefined
              ? action.totalItems
              : state.totalItems,
          totalPages: Math.ceil(
            (action.totalItems || state.totalItems) / state.pageSize,
          ),
        }
      case 'setNextPageToken':
        return {
          ...state,
          nextPageToken: action.nextPageToken,
        }
      case 'setPageSize':
        return {
          ...state,
          pageSize: action.pageSize,
          totalPages: action.totalPages,
          page: action.page,
        }
      case 'setLoading': // loading for ui purposes
        return {
          ...state,
          loading: action.loading,
        }
      default:
        throw new Error(`Invalid pagination action: ${action}`)
    }
  }
  const [
    {
      page,
      totalPages,
      currentPageData,
      loading,
      pageSize,
      totalItems,
      nextPageToken,
    },
    dispatch,
  ] = useReducer(reducer, {
    ...props,
    page: 1,
    currentPageData: [],
    totalItems: 0,
    loading: false,
    totalPages: 1,
  })

  const isWithinRange = (pgNum: number, pgs: number) => {
    return pgNum >= 1 && pgNum <= pgs
  }

  const setPage = (value: number) => {
    if (isWithinRange(value, page + 1)) {
      dispatch({ type: 'setPage', page: value })
    }
  }

  const setPageSize = (value: number) => {
    const newTotalPages = Math.ceil(totalItems / value)
    cache.current = []
    dispatch({
      type: 'setPageSize',
      pageSize: value,
      totalPages: newTotalPages,
      page: 1,
    })
  }
  const hasInitiallyMounted = useRef(false)

  useEffect(() => {
    let ignore = false
    dispatch({ type: 'setLoading', loading: true })
    const cachedData = cache.current.find(
      (cached) => cached.pageNumber === page,
    )
    if (cachedData) {
      // set the cached data
      dispatch({
        type: 'setData',
        currentPageData: cachedData.data,
        page,
      })
      dispatch({ type: 'setLoading', loading: false })
    } else {
      // fetch the new data
      props
        .fetchData({
          page,
          pageSize,
          nextPageToken,
        })
        .then(
          (res: { data: T[]; totalItems: number; nextPageToken?: string }) => {
            if (ignore) return
            // cache the results and set the data
            cache.current = [
              ...cache.current,
              { pageNumber: page, data: res.data },
            ]
            dispatch({
              type: 'setNextPageToken',
              nextPageToken: res.nextPageToken,
            })
            dispatch({
              type: 'setData',
              currentPageData: res.data,
              totalItems: res.totalItems,
              page,
            })
            dispatch({ type: 'setLoading', loading: false })
            setRefetch(false)
            hasInitiallyMounted.current = true
          },
        )
    }
    return () => {
      ignore = true
    }
  }, [page, fetch])

  useEffect(() => {
    // do NOT call this until the above has happened first
    if (!hasInitiallyMounted.current) return
    refetch()
  }, [pageSize, ...(deps || [])])

  // reset cache, page 1 (should be used to reset everything to pg one- example: user changes filter/sort order)
  const refetch = () => {
    cache.current = []
    dispatch({ type: 'setPage', page: 1 })
    dispatch({ type: 'setNextPageToken', nextPageToken: undefined })
    setRefetch(true)
  }

  const findCachedRow = (fnCondition: (item?: T) => boolean) => {
    let existingItem: T | undefined
    const result = cache.current.find(({ data }) => {
      const existing = data.find((item) => fnCondition(item))
      existingItem = existing
      return existing
    })
    const index = result?.data.findIndex((item) => fnCondition(item))
    return result?.pageNumber && index !== undefined && index >= 0
      ? { page: result.pageNumber, index, existingItem } // need to return the item as well
      : undefined
  }

  // allows user to update a row in line without refetching
  const updateRow = (fnCondition: (item?: T) => boolean, updatedData: T) => {
    const result = findCachedRow(fnCondition)
    if (result?.page && result?.index >= 0 && result?.existingItem) {
      // if the item is on the current page, dispatch and update current page data
      if (result.page === page) {
        // update the row in line
        const updated = [
          ...currentPageData.slice(0, result.index),
          { ...result.existingItem, ...updatedData },
          ...currentPageData.slice(result.index + 1),
        ]
        dispatch({ type: 'setData', currentPageData: updated, page })
        // update the cache
        cache.current = [
          ...cache.current.filter((p) => p.pageNumber !== page),
          { pageNumber: page, data: updated },
        ]
      } else {
        // otherwise, only update the cache
        const cachedPgData = cache.current[result.page - 1].data
        const updated = [
          ...cachedPgData.slice(0, result.index),
          { ...result.existingItem, ...updatedData },
          ...cachedPgData.slice(result.index + 1),
        ]
        cache.current = [
          ...cache.current.filter((p) => p.pageNumber !== result.page),
          { pageNumber: result.page, data: updated },
        ]
      }
    }
  }

  return {
    page,
    setPage,
    totalPages,
    pageSize,
    setPageSize,
    currentPageData,
    loading,
    totalItems,
    refetch,
    updateRow,
    findCachedRow,
  }
}
