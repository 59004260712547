import { useEffect, useState, useRef } from 'react'
import {
  Button,
  Fa,
  showSuccessMessage,
  triggerPrompt,
} from '@eltoro-ui/components'
import { useAppContext, useCampaignContext } from 'Contexts'
import { ApiFactory } from 'apiFactory'
import { OrderLinesByCategory } from 'Components/OrderLinesByCategory'
import { checkOLAudienceCount, styleTailwind } from 'Helpers'
import {
  Campaignservicev1OrderLine,
  V1OrderLineStatus,
  V1UpdateOrderLineRequest,
} from 'next-gen-sdk'
import { OrderLineEditWrapper } from 'Components/OrderLineEditWrapper'
import './CampaignSettingsForm.scss'
import { IMPRESSION_MIN } from 'Components/OrderLineEditWrapper/components/shared'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

type SplitOrderLines = {
  currentOrderLines: Campaignservicev1OrderLine[]
  otherOrderLines: Campaignservicev1OrderLine[]
}

const TitleText = styleTailwind(
  'h3',
  'OrderLines__title-text flex justify-between gap-4 pb-4 text-xl font-s font-semibold',
)

export const CampaignSettingsForm = () => {
  const [addButtonDisabled, setAddButtonDisabled] = useState(false)
  const [prompted, setPrompted] = useState(false)
  const { currentOrg, roles, campaignServiceApi, isOnHold } = useAppContext()
  const history = useNavigate()
  const isMounted = useRef(false)
  const {
    orderLines,
    campaign,
    refresh,
    submissionRemovals,
    setRefresh,
    isPolitical,
    orderLinePoliticalFields,
  } = useCampaignContext()

  useEffect(() => {
    if (isOnHold) {
      const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
      history(`/campaigns/dashboard${query}`)
      return
    }
  }, [isOnHold, history, currentOrg?.id])
  useEffect(() => {
    if (isMounted.current) {
      const startInThePastOLs = orderLines?.filter((current) => {
        if (
          !current.locked &&
          dayjs(current.startTime)
            .startOf('day')
            .isBefore(dayjs().startOf('day')) &&
          (current.status === 'ORDERLINE_STATUS_DRAFT' ||
            current.status === 'ORDERLINE_STATUS_REJECTED' ||
            current.status === 'ORDERLINE_STATUS_CREATING')
        ) {
          return current
        }
        return undefined
      })
      if (startInThePastOLs && startInThePastOLs.length > 0) {
        const requests: V1UpdateOrderLineRequest[] = startInThePastOLs.map(
          (cur) => {
            if (dayjs().startOf('day').isBefore(cur.endTime)) {
              return {
                orderLine: {
                  id: cur.id,
                  startTime: dayjs().startOf('day').toDate(),
                  // do not change end time if it is still valid
                },
                orgId: cur.orgId || '',
                updateMask: 'start_time',
              }
            }
            return {
              orderLine: {
                id: cur.id,
                startTime: dayjs().startOf('day').toDate(),
                endTime: dayjs().endOf('day').add(1, 'days').toDate(),
              },
              orgId: cur.orgId || '',
              updateMask: 'start_time,end_time',
            }
          },
        )
        if (!prompted) {
          setPrompted(true)
          triggerPrompt(
            'Start Date in past.',
            'One or more order lines have a start date that is in the past. Confirm to update start date to today.',
            () => {
              return campaignServiceApi
                ?.advertisingPlatformServiceBatchUpdateOrderLines({
                  orgId: currentOrg?.id,
                  requests,
                })
                .then((res) => {
                  if (
                    res &&
                    res.orderLines &&
                    res.orderLines.length === startInThePastOLs.length
                  ) {
                    showSuccessMessage(
                      'Orderlines Updated',
                      `All start dates in the past have been changed to ${dayjs()
                        .utc(true)
                        .startOf('day')
                        .format('MM-DD-YYYY')}`,
                    )
                    setRefresh(true)
                  }
                })
            },
          )
        }
      }
    } else {
      isMounted.current = true
    }
  }, [orderLines])
  const handleAddOrderLine = async () => {
    setAddButtonDisabled(true)
    const { campaignServiceAPI } = ApiFactory(
      localStorage.getItem('eltoro_token'),
    )
    const now = new Date().toUTCString()
    const nowPlusSeven = dayjs().add(8, 'days').endOf('day').utc(true).toDate()
    if (!campaignServiceAPI || !currentOrg?.id) return
    if (isPolitical) {
      campaignServiceAPI
        .advertisingPlatformServiceCreateOrderLine(
          currentOrg?.id,
          {
            name: `${campaign?.name}_Draft_${orderLines?.length}`,
            startTime: new Date(now),
            endTime: nowPlusSeven,
            impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
            political: true,
            politicalFields: orderLinePoliticalFields
              ? orderLinePoliticalFields
              : {
                  orgZip: '',
                  submitterAddress: '',
                  submitterCity: '',
                  submitterName: '',
                  submitterState: '',
                  submitterZip: '',
                  audienceDescription: '',
                  audienceIdsTargeted: '',
                  paidForBy: '',
                },
          },
          campaign?.id,
        )
        .finally(() => {
          setTimeout(() => {
            setRefresh(true)
            setAddButtonDisabled(false)
          }, 1000)
        })
    } else {
      campaignServiceAPI
        .advertisingPlatformServiceCreateOrderLine(
          currentOrg?.id,
          {
            name: `${campaign?.name}_Draft_${orderLines?.length}`,
            startTime: new Date(now),
            endTime: nowPlusSeven,
            impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
          },
          campaign?.id,
        )
        .finally(() => {
          setTimeout(() => {
            setRefresh(true)
            setAddButtonDisabled(false)
          }, 1000)
        })
    }
  }

  // Order lines that stay above the fold:
  // 1. Currently on the 'create' route OR
  // 2. Order lines are draft/creating and created today OR
  // 3. Order lines are draft and have all the requirements: audience, creatives

  // Categorized order lines:
  // 1. Currently on the 'edit' route for campaign creation (not 'create') OR
  // 2. Order lines are draft and already existed before today OR
  // 3. Order lines are the other statuses

  const { currentOrderLines, otherOrderLines }: SplitOrderLines = (
    orderLines || []
  )?.reduce(
    (acc: SplitOrderLines, ol) => {
      const { hasAudienceWithNoMatches } = checkOLAudienceCount(ol)
      if (
        !hasAudienceWithNoMatches &&
        !ol.locked &&
        ol.costRange &&
        ol.costRange.estimate &&
        ol.costRange.estimate.units &&
        ol.audiences &&
        ol.audiences.length > 0 &&
        ol.creatives &&
        ol.creatives.length > 0 &&
        ol.id &&
        ol.status &&
        ol.clickThroughUrl &&
        !submissionRemovals.includes(ol.id) &&
        !([
          'ORDERLINE_STATUS_CREATING',
          'ORDERLINE_STATUS_REVIEW_PENDING',
          'ORDERLINE_STATUS_REVIEW_STARTED',
          'ORDERLINE_STATUS_APPROVED',
          'ORDERLINE_STATUS_DEPLOYING',
          'ORDERLINE_STATUS_DEPLOYED',
          'ORDERLINE_STATUS_PAUSED',
          'ORDERLINE_STATUS_SERVING',
          'ORDERLINE_STATUS_ERRORED_AND_SERVING',
          'ORDERLINE_STATUS_ERRORED_AND_DEPLOYED',
          'ORDERLINE_STATUS_REJECTED',
        ] as V1OrderLineStatus[]).includes(ol.status) &&
        !dayjs(ol.startTime).isBefore(dayjs().utc(true).startOf('day')) &&
        !ol.paid
      ) {
        return { ...acc, currentOrderLines: [...acc.currentOrderLines, ol] }
      }
      // else add to other
      return { ...acc, otherOrderLines: [...acc.otherOrderLines, ol] }
    },
    {
      currentOrderLines: [],
      otherOrderLines: [],
    },
  )

  const ReviewTitle = () => (
    <div className="OrderLines__header-container relative flex h-auto flex-col">
      <TitleText>
        <span className="title-text">
          Review your Order Line
          {orderLines && orderLines.length > 1 ? 's' : ''}
        </span>
        <span className="animate-slidedown description text-l flex items-center font-light">
          To edit order line
          {orderLines && orderLines.length > 1 ? 's, ' : ', '}
          select checkbox{orderLines && orderLines.length > 1 ? 'es ' : ' '}
          below, left
        </span>
      </TitleText>
    </div>
  )
  return (
    <div className="CampaignSettingsForm">
      <div className="OrderLines">
        {currentOrderLines.length ? <ReviewTitle /> : null}
        {campaign && currentOrderLines.length ? (
          <OrderLineEditWrapper
            orderLines={currentOrderLines}
            campaign={campaign}
          />
        ) : null}
        <Button
          iconLeft={<Fa icon="plus" size={1} />}
          onClick={handleAddOrderLine}
          className={orderLines && orderLines.length > 0 ? 'my-4' : undefined}
          disabled={
            roles?.includes('nextgen_read_only') || refresh || addButtonDisabled
          }
        >
          Add Order Line
        </Button>
        {otherOrderLines && otherOrderLines.length > 0 && campaign && (
          <>
            {!currentOrderLines || !currentOrderLines.length ? (
              <ReviewTitle />
            ) : (
              <TitleText>Other Order Lines on Campaign</TitleText>
            )}
            <OrderLinesByCategory
              orderLines={otherOrderLines}
              campaign={campaign}
              defaultOpen={['needs_attention', 'rejected']}
            />
          </>
        )}
      </div>
    </div>
  )
}
