import { useRef, useState, useEffect, useContext } from 'react'
import {
  Button,
  Checkbox,
  Fa,
  FormItem,
  Modal,
  showErrorMessage,
  Text,
  TextInput,
} from '@eltoro-ui/components'
import SignaturePad from 'react-signature-canvas'
import { PDFDocument } from 'pdf-lib'
import { useAuth } from 'react-oidc-context'
import { ThemeContext, useAppContext } from 'Contexts'
import { getCurrentIPAddress, uploadTOSFile } from 'Requests'
import tosPDF from '../../Assets/TermsOfService/TOS - v4.1 32024.pdf'
import ceoSignature from '../../Assets/TermsOfService/ceo_signature.png'
import classNames from 'classnames'
import { PageContent } from 'Components/PageContent'
import './TermsOfServicePage.scss'
import { ErrorMessage } from 'Helpers'
import { useValidation } from '@eltoro-ui/hooks'
import { SelectCountry, SelectState } from 'Components'

export const TermsOfServicePage = ({ onSign }: { onSign: () => void }) => {
  const [
    {
      isAcknowledged,
      isSigned,
      dateSigned,
      signatoryName,
      signatoryTitle,
      customerName,
      customerEmail,
      address,
      city,
      stateOrProvince,
      country,
      zip,
    },
    setFormState,
  ] = useState<{
    isAcknowledged: boolean
    isSigned: boolean
    dateSigned?: Date
    signatoryName: string
    signatoryTitle: string
    customerName: string
    customerEmail: string
    address: string
    city: string
    stateOrProvince: string
    country: string
    zip: string
  }>({
    isAcknowledged: false,
    isSigned: false,
    dateSigned: undefined,
    signatoryName: '',
    signatoryTitle: '',
    customerName: '',
    customerEmail: '',
    address: '',
    city: '',
    stateOrProvince: '',
    country: 'United States of America',
    zip: '',
  })
  const isValidEmail = useValidation('email')
  const isValidZip = useValidation('zip-code')
  const isValidCanadianZip = useValidation('canadian-zip')
  const [formOpen, setFormOpen] = useState(false)
  const auth = useAuth()
  const { currentOrg } = useAppContext()
  const { name: theme } = useContext(ThemeContext)
  const user = auth.user?.profile

  const version = '4.1.0' // currenty manually changing pdf+version

  const sigCanvas = useRef<any>()

  const handleDownloadPDF = () => {
    // TODO: Get this from sunflower endpoint, for now linking to this local file
    const a = document.createElement('a')
    a.href = tosPDF
    a.download = `el_toro_tos_${version}.pdf`
    document.body.appendChild(a)
    a.style.display = 'none'
    a.click()
  }

  const handleSubmit = async () => {
    sigCanvas.current.off()
    const signature = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL('image/png')
    const sigBytes = await fetch(signature).then((res) => res.arrayBuffer())

    const ip = await getCurrentIPAddress().catch((err) => console.error(err))

    // add signature and agreed text to pdf
    const existingPdfBytes = await fetch(tosPDF).then((res) =>
      res.arrayBuffer(),
    )
    const newPDF = await PDFDocument.load(existingPdfBytes)
    newPDF.setTitle(`El Toro Terms of Service (v${version})`)
    const page = newPDF.addPage()
    const pngImage = await newPDF.embedPng(sigBytes)
    const ceoSigBytes = await fetch(ceoSignature).then((res) =>
      res.arrayBuffer(),
    )
    const ceoSigImg = await newPDF.embedPng(ceoSigBytes)

    const pngDims = pngImage.scale(0.5)
    const ceoSigDims = ceoSigImg.scale(0.15)

    const fontSize = 12
    const lineHeight = 16
    const maxWidth = page.getWidth() - 100

    page.moveTo(50, page.getHeight() - 50)
    page.drawText('EL TORO.COM LLC by:', {
      size: fontSize,
    })
    page.moveDown(ceoSigDims.height + 10)
    page.drawImage(ceoSigImg, {
      width: ceoSigDims.width,
      height: ceoSigDims.height,
    })
    page.moveDown(5)
    page.drawSvgPath('M 0, 0 L 250 0')
    page.moveDown(20)
    page.drawText(
      `
Stacy Griggs
Chief Executive Officer
Date: ${dateSigned || new Date()}
    `,
      {
        size: fontSize,
        lineHeight,
        maxWidth,
      },
    )
    page.moveDown(75)
    page.drawText('CUSTOMER by:', {
      size: fontSize,
    })
    page.moveDown(pngDims.height + 10)
    page.drawImage(pngImage, {
      width: pngDims.width,
      height: pngDims.height,
    })
    page.moveDown(5)
    page.drawSvgPath('M 0, 0 L 250 0')
    page.moveDown(20)
    page.drawText(
      `
Printed Name of Signatory: ${signatoryName}
Title of Signatory: ${signatoryTitle}
Date: ${dateSigned}
IP Address: ${ip}

Customer Name: ${customerName}
Customer Email Address: ${customerEmail}
Customer Mailing Address:
${address}
${city}, ${stateOrProvince} ${zip}
${country}
    `,
      {
        size: fontSize,
        lineHeight,
        maxWidth,
      },
    )

    const pdfBytes = await newPDF.save()

    const blob = new Blob([pdfBytes], {
      type: 'application/pdf',
    })
    const file = new File([blob], `tos_${currentOrg?.name}_${currentOrg?.id}`, {
      type: 'image/jpeg',
    })
    // For testing the output of the PDF
    // const url = window.URL.createObjectURL(blob)
    // setTimeout(() => window.URL.revokeObjectURL(url), 1000)

    // const a = document.createElement('a')
    // a.href = url
    // // a.download = 'tos.pdf' // for downloading instead of opening in new tab
    // a.target = '_blank'
    // document.body.appendChild(a)
    // a.style.display = 'none'
    // a.click()

    if (!signatoryName) {
      showErrorMessage(
        'Signatory name required',
        'Please provide the required fields.',
      )
      return
    }

    let firstName = signatoryName
    let lastName = '*'
    const splitName = signatoryName.split(' ')
    if (splitName.length > 1) {
      firstName = splitName[0]
      lastName = splitName.slice(1).join(' ') // set to the rest of the split
    }
    await uploadTOSFile(file, {
      first_name: firstName,
      last_name: lastName,
      org_id: currentOrg?.id || '',
      org_name: currentOrg?.name || '',
      user_id: user?.sub || '',
      version,
    })
      .then(() => onSign())
      .catch(() =>
        showErrorMessage(
          'Error submitting Conditions of Use Agreement',
          'Please try again. If you are still experiencing issues, please contact support@eltoro.com',
        ),
      )
  }

  useEffect(() => {
    if (isAcknowledged && isSigned)
      setFormState((prev) => ({ ...prev, dateSigned: new Date() }))
    else setFormState((prev) => ({ ...prev, dateSigned: undefined }))
  }, [isAcknowledged, isSigned])

  const isValidCustomerEmail = isValidEmail(customerEmail)

  return (
    <PageContent className="TermsOfServicePage">
      <div className="TermsOfServicePage__iframe-container relative m-auto h-full w-full">
        <iframe
          src={`${tosPDF}#view=FitH&toolbar=0&navpanes=0`}
          title="El Toro Conditions of Use"
          className="TermsOfServicePage__pdf-container h-full w-full"
        >
          <p>
            This browser does not support PDFs. Please Download the Conditions
            of Use using the button below.
          </p>
        </iframe>
      </div>
      <div className="TermsOfServicePage__footer sticky bottom-0 flex flex-col py-3">
        <div className="flex flex-wrap justify-between">
          <span
            aria-label="Download a copy of the terms of service"
            data-tooltip="right"
          >
            <Button
              onClick={handleDownloadPDF}
              iconLeft={<Fa icon="file-arrow-down" size={1} />}
            >
              Download PDF
            </Button>
          </span>
          <span
            aria-label="Proceed to signing terms of service"
            data-tooltip="left"
          >
            <Button onClick={() => setFormOpen(true)} kind="primary">
              Next
            </Button>
          </span>
        </div>
      </div>
      {formOpen && (
        <Modal
          header={<p>Sign Terms of Service</p>}
          offClick={() => setFormOpen(false)}
          className="min-w-[50%]"
        >
          <div className="TermsOfServicePage__form-modal flex flex-col gap-2">
            <div className="TermsOfServicePage__signature-form text-center">
              <div className="m-auto my-2 flex max-w-[500px] items-center justify-between">
                <Text className="font-thin">
                  Draw your signature with your mouse
                </Text>
                <Button
                  onClick={() => {
                    sigCanvas.current.clear()
                    setFormState((prev) => ({ ...prev, isSigned: false }))
                  }}
                  iconLeft={<Fa icon="xmark" size={1} />}
                  size="s"
                  disabled={!isSigned}
                >
                  Clear Signature
                </Button>
              </div>
              <div
                className={classNames('TermsOfServicePage__sig-pad-container', {
                  invert: theme === 'darkBlue' || theme === 'dark',
                })}
              >
                {/* @ts-ignore */}
                <SignaturePad
                  ref={sigCanvas}
                  backgroundColor="hsl(48, 23%, 93%)"
                  canvasProps={{
                    width: 500,
                    height: 100,
                    className:
                      'TermsOfServicePage__sigCanvas border border-grey-300 m-auto mb-1',
                  }}
                  onEnd={() => {
                    if (!sigCanvas.current?.isEmpty())
                      setFormState((prev) => ({ ...prev, isSigned: true }))
                  }}
                />
              </div>
            </div>
            <FormItem
              wrapperClassname="TermsOfServicePage__form-item flex flex-col"
              htmlFor="signatoryName"
              label={<span>Signatory Name</span>}
              required
            >
              <TextInput
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    signatoryName: e.target.value,
                  }))
                }
                value={signatoryName}
              />
            </FormItem>
            <FormItem
              wrapperClassname="TermsOfServicePage__form-item flex flex-col"
              htmlFor="signatoryTitle"
              label={<span>Signatory Title</span>}
              required
            >
              <TextInput
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    signatoryTitle: e.target.value,
                  }))
                }
                value={signatoryTitle}
              />
            </FormItem>
            <FormItem
              wrapperClassname="TermsOfServicePage__form-item flex flex-col"
              htmlFor="customerName"
              label={
                <span>
                  Customer Name (please provide business's legal name)
                </span>
              }
              required
            >
              <TextInput
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    customerName: e.target.value,
                  }))
                }
                value={customerName}
              />
            </FormItem>
            <FormItem
              wrapperClassname="TermsOfServicePage__form-item flex flex-col"
              htmlFor="email"
              label={
                <span>
                  Customer Email Address (please provide the business email
                  address where legal notices should be sent)
                </span>
              }
              required
              errorMessage={ErrorMessage({
                fieldName: customerEmail || '',
                max: 255,
                label: 'Email address',
                isValid: !isValidCustomerEmail,
              })}
              valid={isValidCustomerEmail}
            >
              <TextInput
                maxLength={256}
                value={customerEmail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormState((prev) => ({
                    ...prev,
                    customerEmail: e.target.value,
                  }))
                }
              />
            </FormItem>
            <label>
              Customer Mailing Address (please provide the business address
              where legal notices should be sent)
            </label>
            <FormItem required htmlFor="customerAddress" label="Address">
              <TextInput
                maxLength={256}
                value={address}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormState((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }
              />
            </FormItem>
            <FormItem
              required
              htmlFor="country"
              label="Country"
              errorMessage="Please select a country"
            >
              <SelectCountry
                value={country}
                onChange={(country) =>
                  setFormState((prev) => ({
                    ...prev,
                    country,
                  }))
                }
              />
            </FormItem>
            <div className="flex gap-2">
              <FormItem className="flex-1" required htmlFor="city" label="City">
                <TextInput
                  maxLength={256}
                  value={city}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormState((prev) => ({
                      ...prev,
                      city: e.target.value,
                    }))
                  }
                />
              </FormItem>
              <FormItem
                required
                htmlFor="stateOrProvince"
                label={country === 'Canada' ? 'Province' : 'State'}
                errorMessage={`Please enter a valid ${
                  country === 'Canada' ? 'province' : 'state'
                }`}
              >
                <SelectState
                  country={country}
                  value={stateOrProvince}
                  onChange={(state) =>
                    setFormState((prev) => ({
                      ...prev,
                      stateOrProvince: state,
                    }))
                  }
                />
              </FormItem>

              <FormItem
                required
                htmlFor="zip"
                label="ZIP code"
                errorMessage={ErrorMessage({
                  fieldName: zip,
                  max: 5,
                  label: 'ZIP code',
                })}
                valid={
                  country === 'Canada'
                    ? isValidCanadianZip(zip)
                    : isValidZip(zip)
                }
                counter={zip.length > 0}
              >
                <TextInput
                  type="text"
                  maxLength={7}
                  value={zip}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormState((prev) => ({
                      ...prev,
                      zip: e.target.value,
                    }))
                  }
                />
              </FormItem>
            </div>
            <div className="TermsOfServicePage__agree-checkbox m-auto p-2">
              <Checkbox
                checked={isAcknowledged}
                onChange={(val) =>
                  setFormState((prev) => ({ ...prev, isAcknowledged: val }))
                }
                label={
                  <p>
                    By checking this box, I hereby affirm that I am authorized
                    to access and use the El Toro Portal on behalf of the
                    “Customer” as defined by the El Toro Terms of Service
                    (“TOS”). Further, I have read, understand, and agree to the
                    El Toro “Website and Other Online Services Terms and
                    Conditions of Use” available at{' '}
                    <a
                      href="https://eltoro.com/terms-service/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://eltoro.com/terms-service
                    </a>{' '}
                    and affirm that my use of the El Toro Portal is governed by
                    and shall be in accordance with those terms and conditions
                    of use in addition to the TOS.
                  </p>
                }
              />
            </div>
            <div className="flex items-center gap-2 self-end">
              {dateSigned && (
                <div className="text-success text-sm font-bold">
                  Agreed on {dateSigned.toLocaleDateString()} at{' '}
                  {dateSigned.toLocaleTimeString()}
                </div>
              )}
              <Button
                onClick={handleSubmit}
                kind="primary"
                disabled={
                  !isAcknowledged ||
                  !isSigned ||
                  !signatoryName ||
                  !signatoryTitle ||
                  !customerName ||
                  !customerEmail ||
                  !isValidCustomerEmail ||
                  (country === 'Canada' && !isValidCanadianZip(zip)) ||
                  (country === 'United States of America' &&
                    !isValidZip(zip)) ||
                  !country ||
                  !city ||
                  !stateOrProvince ||
                  !address ||
                  !zip
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </PageContent>
  )
}
