import { Billingservicev1InvoiceStatus } from 'next-gen-sdk'
import { InvoiceStatusIndicator } from '../InvoiceStatusIndicator'
import './InvoiceStatusPopover.scss'

const statusTexts: Array<{
  status: Billingservicev1InvoiceStatus
  text: string
}> = [
  {
    status: 'INVOICE_STATUS_PAID',
    text: 'The invoice is paid',
  },
  {
    status: 'INVOICE_STATUS_PROCESSING',
    text: 'The credit card has been approved',
  },
  {
    status: 'INVOICE_STATUS_DECLINED',
    text: 'The credit card has been declined',
  },
  {
    status: 'INVOICE_STATUS_PAYMENT_DUE',
    text: 'The invoice is unpaid',
  },
  {
    status: 'INVOICE_STATUS_PARTIALLY_PAID',
    text: 'The invoice is partially paid and a balance exists',
  },
  {
    status: 'INVOICE_STATUS_PAST_DUE',
    text: 'Invoice is past due date',
  },
]

export const InvoiceStatusPopover = () => {
  return (
    <div className="InvoiceStatusPopover">
      <h3>Invoice Statuses</h3>
      {statusTexts.map(({ status, text }) => {
        return (
          <div className="InvoiceStatusPopover__row" key={status}>
            <div className="InvoiceStatusPopover__status-container">
              <InvoiceStatusIndicator status={status} />
            </div>
            <p>{text}</p>
          </div>
        )
      })}
    </div>
  )
}
