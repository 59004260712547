import {
  Campaignservicev1AdType,
  Campaignservicev1Creative,
  V1CreativeFile,
} from 'next-gen-sdk'
import { LoadingBar, TextHeader } from '@eltoro-ui/components'
import { ETIcon } from 'Assets'
import {
  isBannerAndMissingATop5Dimension,
  isVideo,
  simplifyEnum,
} from 'Helpers'
import { IconThumbnailWrapper, WarningMsg } from 'Components'
import { useGetFullOLCreatives } from 'Hooks'

export const CreativePopover = ({
  contentRef,
  getFileThumbnail,
  setPreviewedFile,
  setShowAllCreatives,
  orgId,
  adType,
  creatives,
}: {
  contentRef: React.RefObject<HTMLDivElement>
  getFileThumbnail: (
    file: V1CreativeFile,
    cover: boolean,
    onClick?: () => void,
  ) => JSX.Element
  setPreviewedFile: React.Dispatch<
    React.SetStateAction<V1CreativeFile | undefined>
  >
  setShowAllCreatives: React.Dispatch<React.SetStateAction<boolean>>
  orgId?: string
  adType?: Campaignservicev1AdType
  creatives?: Campaignservicev1Creative[]
}) => {
  const [olCreatives, loadingCreatives] = useGetFullOLCreatives(
    creatives,
    orgId,
  )

  const firstCreative = olCreatives?.[0]

  const hasCreativeMissingTop5Dimension = () =>
    olCreatives &&
    firstCreative?.type &&
    (firstCreative.type === 'CREATIVE_TYPE_BANNER' ||
      firstCreative.type === 'CREATIVE_TYPE_HTML5' ||
      firstCreative.type === 'CREATIVE_TYPE_AD_TAG') &&
    !!olCreatives.find((creative) => isBannerAndMissingATop5Dimension(creative))

  const hasCreativeAboveRecommendedSize = () =>
    olCreatives &&
    !!olCreatives.find(
      (creative) =>
        creative.type === 'CREATIVE_TYPE_BANNER' &&
        creative.files?.[0]?.size &&
        +creative.files?.[0]?.size > 100000,
    )

  const hasOTTWarning = () =>
    olCreatives &&
    !!olCreatives.find((creative) => creative.type === 'CREATIVE_TYPE_OTT')

  const popoverText = 'View Specs'

  return (
    <div
      className="CreativesCardItem__popover-content border-tint-gray-300 border-thin bg-base flex max-w-xl flex-col gap-3 rounded p-0 shadow-lg"
      ref={contentRef}
    >
      <TextHeader
        type={6}
        className="CreativesCardItem__popover-content--title"
      >
        Creative Type: {adType ? simplifyEnum(2, adType) : 'Unknown'}
      </TextHeader>

      <div className="CreativesCardItem__thumb-container flex flex-1 flex-wrap justify-start gap-2">
        {loadingCreatives ? (
          <LoadingBar />
        ) : (
          olCreatives.map((creative) => {
            const file = creative.files?.[0]
            return (
              <div
                key={creative.id}
                className="CreativesCardItem__thumb-item bg-base mx-auto flex flex-1 flex-col items-center gap-2"
              >
                {file && creative.type !== 'CREATIVE_TYPE_HTML5' ? (
                  getFileThumbnail(file, false, () => {
                    setPreviewedFile(file)
                    setShowAllCreatives(false)
                  })
                ) : (
                  <IconThumbnailWrapper>
                    {(() => {
                      if (creative.type === 'CREATIVE_TYPE_HTML5')
                        return <ETIcon className="HTML5Icon" icon="html5" />
                      if (
                        creative.type === 'CREATIVE_TYPE_AD_TAG' ||
                        creative.type === 'CREATIVE_TYPE_VAST_TAG'
                      )
                        return <ETIcon icon="ad-tag" />
                    })()}
                  </IconThumbnailWrapper>
                )}
                <p className="text-xs">
                  Size:&nbsp;
                  {(() => {
                    if (creative.type === 'CREATIVE_TYPE_AD_TAG')
                      return `${creative.width}x${creative.height}`
                    if (file?.mime && isVideo(file.mime))
                      return `${file?.duration} seconds`
                    if (file?.width !== undefined && file?.height !== undefined)
                      return `${file.width}x${file.height}`
                  })()}
                </p>
              </div>
            )
          })
        )}
      </div>
      <>
        {hasCreativeMissingTop5Dimension() && (
          <WarningMsg popoverText={popoverText}>
            One or more of your creative(s) is not in the top 5 recommended
            sizes. This <strong>may</strong> limit our serving capacity.
          </WarningMsg>
        )}
        {hasCreativeAboveRecommendedSize() && (
          <WarningMsg popoverText={popoverText}>
            One or more of your creative(s) have a file size above our
            recommended specifications. This <strong>may</strong> limit our
            serving capacity. Recommended file size is less than 100kb.
          </WarningMsg>
        )}
        {hasOTTWarning() && (
          <WarningMsg popoverText={popoverText}>
            One or more of your creative(s) do not meet our recommended
            specifications for optimal delivery. This <strong>may</strong> limit
            our serving capacity.
          </WarningMsg>
        )}
      </>
    </div>
  )
}
