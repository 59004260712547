import { ReactElement, useEffect, useState } from 'react'
import { Fa, NotificationBar } from '@eltoro-ui/components'
import { Notificationservicev1Type, V1Notification } from 'next-gen-sdk'
import { useAppContext, useNotificationContext } from 'Contexts'
import { Link } from 'react-router-dom'

type AccountingHoldAlert = {
  id?: string
  type?: string
  title?: string
  message?: string | ReactElement
}

export const checkIfNotification = (
  item: AccountingHoldAlert | V1Notification,
): item is V1Notification => {
  return !!(item as V1Notification).active
}

export const GlobalAlert = () => {
  const [showAll, setShowAll] = useState(false)
  const [currentAlerts, setCurrentAlerts] = useState<
    Array<AccountingHoldAlert | V1Notification>
  >([])
  const { alertsList } = useNotificationContext()
  const { currentOrg, currentBillingOrg } = useAppContext()

  const onDismissAlert = (id: string) => {
    const dismissed = window.localStorage.getItem('dismissed-global-msgs')
    const dismissedMsgs: string[] | undefined =
      dismissed && JSON.parse(dismissed)
    if (!dismissedMsgs?.includes(id)) {
      window.localStorage.setItem(
        'dismissed-global-msgs',
        JSON.stringify([...(dismissedMsgs || []), id]),
      )
      setCurrentAlerts((prev) => prev.filter((al) => al.id !== id))
    }
  }

  const [firstGlobalAlert] = currentAlerts

  useEffect(() => {
    const dismissed = window.localStorage.getItem('dismissed-global-msgs')

    const dismissedMsgs = JSON.parse(dismissed || '[]')

    const order: Notificationservicev1Type[] = [
      'NOTIFICATION_TYPE_ERROR',
      'NOTIFICATION_TYPE_WARNING',
      'NOTIFICATION_TYPE_INFO',
      'NOTIFICATION_TYPE_UNSPECIFIED',
    ]

    const nonDismissedAlerts = alertsList
      .filter((notification) => !dismissedMsgs.includes(notification.id))
      .sort(
        (a, b) =>
          order.indexOf(a.type || 'NOTIFICATION_TYPE_UNSPECIFIED') -
          order.indexOf(b.type || 'NOTIFICATION_TYPE_UNSPECIFIED'),
      )
    // get the hold/billing alerts
    const holdBillingAlerts: AccountingHoldAlert[] = [
      ...(currentOrg?.accountingHold
        ? [
            {
              id: `${currentOrg.id}-accounting-hold`,
              title: 'Your account is on accounting hold.',
              type: 'error',
              message: (
                <span>
                  This account is blocked from submitting new campaigns or
                  editing existing campaigns. Please check your email for more
                  information. If you have any questions, reach out to your
                  sales executive or &nbsp;
                  <a className="text-danger" href="mailto:billing@eltoro.com">
                    billing@eltoro.com
                  </a>
                </span>
              ),
            },
          ]
        : []),
      ...(currentOrg?.billingHold
        ? [
            {
              id: `${currentOrg.id}-billing-hold`,
              title: 'Your account is on billing hold.',
              type: 'error',
              message: (
                <span>
                  This account is blocked from submitting new campaigns or
                  editing existing campaigns. Please check your email for more
                  information. If you have any questions, reach out to your
                  sales executive or &nbsp;
                  <a className="text-danger" href="mailto:billing@eltoro.com">
                    billing@eltoro.com
                  </a>
                </span>
              ),
            },
          ]
        : []),
      ...(currentOrg?.adminAccountingHold
        ? [
            {
              id: `${currentOrg.id}-admin-accounting-hold`,
              title: 'Your account is on administrative accounting hold',
              type: 'error',
              message: (
                <span>
                  All active campaigns have been paused. New campaigns cannot be
                  submitted on this account. Reach out to your sales executive
                  or &nbsp;
                  <a className="text-danger" href="mailto:billing@eltoro.com">
                    support@eltoro.com
                  </a>
                </span>
              ),
            },
          ]
        : []),
      ...(currentOrg?.setupFeePaid === false && currentOrg?.billableOrgId === ''
        ? [
            {
              id: `${currentOrg?.id}-set-up-fee-not-paid`,
              title: 'Set up fee payment required',
              type: 'error',
              message: (
                <span>
                  Your organization still needs to pay the initial set up fee of
                  ${currentOrg?.initialSetupFee}. Please go to the&nbsp;
                  <Link to={`/orgs/${currentOrg?.id}/billing`}>
                    billing page
                  </Link>{' '}
                  to ensure that the set up fee is paid and your details are
                  updated. Reach out to your sales executive or{' '}
                  <a className="text-danger" href="mailto:billing@eltoro.com">
                    billing@eltoro.com
                  </a>{' '}
                  for assistance.
                </span>
              ),
            },
          ]
        : []),
      ...(currentBillingOrg?.isBillingReady === false &&
      currentOrg?.billableOrgId === ''
        ? [
            {
              id: `${currentOrg?.id}-is-billing-ready`,
              title: 'Billing details required',
              type: 'warning',
              message: (
                <span>
                  Billing for this org is not ready. Please update your billing
                  details on the{' '}
                  <Link to={`/orgs/${currentOrg?.id}/billing`}>
                    billing settings
                  </Link>{' '}
                  page.
                </span>
              ),
            },
          ]
        : []),
      ...(currentBillingOrg?.areCreditCardsReady === false &&
      currentOrg?.billableOrgId === ''
        ? [
            {
              id: `${currentOrg?.id}-are-credit-cards-ready`,
              title: 'Add a valid payment method',
              type: 'warning',
              message: (
                <span>
                  Your account is missing a valid credit card on file. Please go
                  to{' '}
                  <Link to={`/orgs/${currentOrg?.id}/billing`}>
                    billing settings
                  </Link>{' '}
                  and add a valid credit card to submit campaigns.
                </span>
              ),
            },
          ]
        : []),
      ...(currentBillingOrg &&
      currentBillingOrg?.creditCards?.find(
        (cc) =>
          !cc.authorization ||
          !cc.authorization.approved ||
          !cc.authorization.signature,
      ) &&
      !currentOrg?.billableOrgId &&
      currentBillingOrg.areCreditCardsReady // there is a valid card but user needs to update unauthorized
        ? [
            {
              id: `${currentOrg?.id}-no-authorized-cards`,
              title: 'Update unauthorized credit card',
              type: 'warning',
              message: (
                <span>
                  One or more of your credit cards are not authorized or signed.
                  Please go to{' '}
                  <Link to={`/orgs/${currentOrg?.id}/billing`}>
                    billing settings
                  </Link>{' '}
                  to update the card.
                </span>
              ),
            },
          ]
        : []),
    ]
    setCurrentAlerts([...holdBillingAlerts, ...nonDismissedAlerts])
  }, [
    alertsList,
    currentBillingOrg,
    currentBillingOrg?.areCreditCardsReady,
    currentBillingOrg?.creditCards,
    currentBillingOrg?.isBillingReady,
    currentOrg?.accountingHold,
    currentOrg?.adminAccountingHold,
    currentOrg?.billableOrgId,
    currentOrg?.billingHold,
    currentOrg?.id,
    currentOrg?.initialSetupFee,
    currentOrg?.setupFeePaid,
  ])

  const messageType = (
    noteType: Notificationservicev1Type | string | undefined,
  ): string => {
    if (noteType === 'NOTIFICATION_TYPE_ERROR' || noteType === 'error')
      return 'error'
    if (noteType === 'NOTIFICATION_TYPE_WARNING' || noteType === 'warning')
      return 'warning'
    return 'info'
  }

  if (!currentAlerts.length || !firstGlobalAlert) return null

  return (
    <div className="GlobalAlert">
      <div id="alert-wrap" className="divide-tint-gray-200 divide-y">
        {(showAll ? currentAlerts : [currentAlerts[0]]).map((alert) => (
          <NotificationBar
            key={alert.id}
            className="alert-banner !shadow-none"
            messageType={messageType(alert.type)}
            timer={30000}
            title={alert.title}
            message={alert.message}
            autoDismiss={false}
            dismissMessage={checkIfNotification(alert)} // only allow dismissal of notification alerts (not hold+billing alerts)
            alert
            onDismiss={() => alert.id && onDismissAlert(alert.id)}
          />
        ))}
      </div>
      {currentAlerts.length > 1 && (
        <div className="GlobalAlert__button--wrap">
          <button
            type="button"
            onClick={() => setShowAll(!showAll)}
            className="Button bg-base group z-10 gap-2"
          >
            <p className={showAll ? 'mt-1' : ''}>
              {!showAll ? 'Show all' : 'Close'}
            </p>
            {!showAll ? (
              <div className="bg-primary group-hover:bg-base group-hover:text-primary flex min-h-[1rem] min-w-[1rem] items-center justify-center rounded-full text-xs font-bold text-white">
                {currentAlerts.length - 1}
              </div>
            ) : (
              <Fa icon="chevron-up" size={1} />
            )}
          </button>
        </div>
      )}
    </div>
  )
}
