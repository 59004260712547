import day from 'dayjs'
import { useBrowser } from '@eltoro-ui/hooks'
import { V1Notification } from 'next-gen-sdk'
import { getSanitizedHTML } from 'Helpers'
import { Fa, Loader } from '@eltoro-ui/components'
import './HeaderNotification.scss'
import { useState } from 'react'
import { useNotificationContext } from 'Contexts'

const ListGroupItem = ({
  isNew,
  alert,
}: {
  isNew: boolean
  alert: V1Notification
}) => {
  const Browser = useBrowser()
  const [open, setOpen] = useState(false)
  const sanitizedMessage = getSanitizedHTML(alert.message as string, Browser)

  return (
    <li className="NotificationItem__list-group-li">
      <div className="NotificationItem">
        <div className="NotificationItem__time mt-2 flex justify-between">
          {isNew && <span className="status new">New</span>}
          <span className="time">
            {day(alert.updateTime).format('M/D/YYYY [at] h:mma [ET]')}
          </span>
        </div>

        <div className="NotificationItem__body">
          <p className="NotificationItem__text flex gap-1">
            {alert.global && (
              <Fa
                icon={(() => {
                  if (alert.type === 'NOTIFICATION_TYPE_ERROR')
                    return 'fa-shield-exclamation'
                  if (alert.type === 'NOTIFICATION_TYPE_WARNING')
                    return 'fa-circle-exclamation'
                  return 'fa-circle-info'
                })()}
                size={1}
                className={(() => {
                  if (alert.type === 'NOTIFICATION_TYPE_ERROR')
                    return 'text-danger'
                  if (alert.type === 'NOTIFICATION_TYPE_WARNING')
                    return 'text-warning'
                  return 'text-secondary-500'
                })()}
              />
            )}
            {alert.title}
          </p>
          <button
            data-tooltip="top"
            aria-label={`Click to ${open ? 'hide' : 'read'} message`}
            type="button"
            className="NotificationItem__menu-button group self-end"
            onClick={() => setOpen(!open)}
          >
            <Fa
              className="group-hover:bg-primary ml-auto mr-1 rounded-full transition-all group-hover:text-white"
              icon="ellipsis-v"
              size={2}
            />
          </button>
        </div>
      </div>
      {open && (
        <div
          className="ReadMessage NotificationMessage NotificationMessage__text-inner-html animate-slidedown"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
        />
      )}
    </li>
  )
}

export const HeaderNotification = () => {
  const { loading, newNotifications } = useNotificationContext()

  return (
    <div
      tabIndex={-1}
      role="menu"
      aria-hidden="false"
      className="HeaderNotification Extended-dropdown dropdown-menu dropdown-menu-right"
    >
      <div className="Extended-dropdown__section items-center px-3 pb-2">
        <h6 className="Extended-dropdown__section-header">
          Notification{newNotifications.length > 1 ? 's' : ''}
        </h6>
      </div>
      {loading && <Loader />}
      {!loading && (
        <div className="Extended-dropdown__section Extended-dropdown__section--list flex flex-col">
          {newNotifications.length > 0 ? (
            <ul className="NotificationItem__list-group">
              {newNotifications.map((alert: V1Notification, index) => {
                return (
                  <ListGroupItem
                    key={`${alert.id}_${index}`}
                    isNew={newNotifications.length > 0}
                    alert={alert}
                  />
                )
              })}
              {newNotifications.length > 8 && (
                <li>
                  <a
                    className="Extended-dropdown__section-footer text-m flex items-center justify-center gap-1 pt-3 text-center font-light"
                    href="/alerts"
                  >
                    <Fa
                      className="px-1"
                      icon="party-horn"
                      size={1}
                      type="duotone"
                    />
                    <span>End of Notifications</span>
                  </a>
                </li>
              )}
            </ul>
          ) : (
            <div className="mx-auto my-8 h-5 text-center">
              No current notification available
            </div>
          )}
        </div>
      )}
    </div>
  )
}
