/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Billingservicev2InvoiceStatus } from '../models/Billingservicev2InvoiceStatus';
import { HttpFile } from '../http/http';

/**
* Invoice
*/
export class Billingservicev2Invoice {
    /**
    * ID of the invoice
    */
    'id'?: string;
    /**
    * Due date of the invoice
    */
    'whenDue'?: string;
    'status'?: Billingservicev2InvoiceStatus;
    /**
    * Total cost of the invoice
    */
    'total'?: number;
    /**
    * Total money due
    */
    'totalDue'?: number;
    /**
    * Total money paid towards total amount
    */
    'appliedAmount'?: number;
    /**
    * Name of org
    */
    'orgName'?: string;
    /**
    * Download key of the invoice
    */
    'downloadKey'?: string;
    /**
    * Record id of the invoice
    */
    'recordId'?: string;
    /**
    * The timestamp of when the invoice was created
    */
    'createTime'?: Date;
    /**
    * The timestamp of when the invoice was updatet last time
    */
    'updateTime'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "whenDue",
            "baseName": "when_due",
            "type": "string",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Billingservicev2InvoiceStatus",
            "format": ""
        },
        {
            "name": "total",
            "baseName": "total",
            "type": "number",
            "format": "float"
        },
        {
            "name": "totalDue",
            "baseName": "total_due",
            "type": "number",
            "format": "float"
        },
        {
            "name": "appliedAmount",
            "baseName": "applied_amount",
            "type": "number",
            "format": "float"
        },
        {
            "name": "orgName",
            "baseName": "org_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "downloadKey",
            "baseName": "download_key",
            "type": "string",
            "format": ""
        },
        {
            "name": "recordId",
            "baseName": "record_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return Billingservicev2Invoice.attributeTypeMap;
    }

    public constructor() {
    }
}

