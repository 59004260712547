/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Orgmanagerv1CPM } from '../models/Orgmanagerv1CPM';
import { Orgmanagerv1CommissionGroup } from '../models/Orgmanagerv1CommissionGroup';
import { Orgmanagerv1Contact } from '../models/Orgmanagerv1Contact';
import { Orgmanagerv1Note } from '../models/Orgmanagerv1Note';
import { Orgmanagerv1Status } from '../models/Orgmanagerv1Status';
import { Orgmanagerv1User } from '../models/Orgmanagerv1User';
import { V1OrgLogo } from '../models/V1OrgLogo';
import { HttpFile } from '../http/http';

/**
* Org contains information relating to a client Organization
*/
export class Orgmanagerv1Org {
    /**
    * ID of the Organization
    */
    'id'?: string;
    /**
    * Name associated with this Organization
    */
    'name'?: string;
    /**
    * ID of the parent org
    */
    'parentOrgId'?: string;
    /**
    * Date and time that the org was created in the database
    */
    'createTime'?: Date;
    /**
    * Date and time of the last time the org's information was updated
    */
    'updateTime'?: Date;
    /**
    * Date and time that the org was deleted from record
    */
    'deleteTime'?: Date;
    'status'?: Orgmanagerv1Status;
    /**
    * List of child org on this Org
    */
    'childOrgs'?: Array<Orgmanagerv1Org>;
    /**
    * // Deprecated. List of account rep users that are associated with the org
    */
    'accountReps'?: Array<Orgmanagerv1User>;
    /**
    * // Deprecated. List of sales representatives associated with the org
    */
    'salesRepIds'?: Array<string>;
    /**
    * List of contacts that are associated with this org
    */
    'contacts'?: Array<Orgmanagerv1Contact>;
    /**
    * Users associated to the org
    */
    'userIds'?: Array<string>;
    /**
    * Reference ID
    */
    'refId'?: string;
    /**
    * Map of related costs for this org (example: 'ip_targeting banner' => $15.50)
    */
    'cpms'?: { [key: string]: Orgmanagerv1CPM; };
    /**
    * Whether or not this is an authorized reseller Organization
    */
    'reseller'?: boolean;
    'logo'?: V1OrgLogo;
    /**
    * Whether or not the org needs to prepay for orderlines
    */
    'prepay'?: boolean;
    /**
    * Whether or not the org has signed the MSA
    */
    'msaSigned'?: boolean;
    /**
    * Whether or not an org is billable
    */
    'billableOrgId'?: string;
    /**
    * Ad-Ops Account Freeze
    */
    'accountingHold'?: boolean;
    /**
    * Administrative Account Suspension
    */
    'adminAccountingHold'?: boolean;
    /**
    * Accounting Overdue Hold
    */
    'billingHold'?: boolean;
    /**
    * The fee for initial setup of the Org
    */
    'initialSetupFee'?: number;
    /**
    * Whether or not the initial setup fee is paid
    */
    'setupFeePaid'?: boolean;
    /**
    * Whether or not to override the credit card requirement
    */
    'overrideCreditCardRequirement'?: boolean;
    /**
    * Whether or not to override the prepay balance
    */
    'overridePrepayBalance'?: boolean;
    /**
    * Whether or not the Org is in collections
    */
    'inCollections'?: boolean;
    /**
    * Whether the org should receive notifications
    */
    'notificationsDisabled'?: boolean;
    'minimumImpressions'?: number;
    'commissionGroup'?: Orgmanagerv1CommissionGroup;
    'commissionGroupId'?: string;
    'notes'?: Array<Orgmanagerv1Note>;
    /**
    * Date and time that the org was last synced from v2
    */
    'lastSyncTime'?: Date;
    /**
    * Whether or not the org is active
    */
    'isActive'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "parentOrgId",
            "baseName": "parent_org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Orgmanagerv1Status",
            "format": ""
        },
        {
            "name": "childOrgs",
            "baseName": "child_orgs",
            "type": "Array<Orgmanagerv1Org>",
            "format": ""
        },
        {
            "name": "accountReps",
            "baseName": "account_reps",
            "type": "Array<Orgmanagerv1User>",
            "format": ""
        },
        {
            "name": "salesRepIds",
            "baseName": "sales_rep_ids",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "contacts",
            "baseName": "contacts",
            "type": "Array<Orgmanagerv1Contact>",
            "format": ""
        },
        {
            "name": "userIds",
            "baseName": "user_ids",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "cpms",
            "baseName": "cpms",
            "type": "{ [key: string]: Orgmanagerv1CPM; }",
            "format": ""
        },
        {
            "name": "reseller",
            "baseName": "reseller",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "logo",
            "baseName": "logo",
            "type": "V1OrgLogo",
            "format": ""
        },
        {
            "name": "prepay",
            "baseName": "prepay",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "msaSigned",
            "baseName": "msa_signed",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "billableOrgId",
            "baseName": "billable_org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "accountingHold",
            "baseName": "accounting_hold",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "adminAccountingHold",
            "baseName": "admin_accounting_hold",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "billingHold",
            "baseName": "billing_hold",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "initialSetupFee",
            "baseName": "initial_setup_fee",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "setupFeePaid",
            "baseName": "setup_fee_paid",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "overrideCreditCardRequirement",
            "baseName": "override_credit_card_requirement",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "overridePrepayBalance",
            "baseName": "override_prepay_balance",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "inCollections",
            "baseName": "in_collections",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "notificationsDisabled",
            "baseName": "notifications_disabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "minimumImpressions",
            "baseName": "minimum_impressions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "commissionGroup",
            "baseName": "commission_group",
            "type": "Orgmanagerv1CommissionGroup",
            "format": ""
        },
        {
            "name": "commissionGroupId",
            "baseName": "commission_group_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "notes",
            "baseName": "notes",
            "type": "Array<Orgmanagerv1Note>",
            "format": ""
        },
        {
            "name": "lastSyncTime",
            "baseName": "last_sync_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "isActive",
            "baseName": "is_active",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Orgmanagerv1Org.attributeTypeMap;
    }

    public constructor() {
    }
}

