/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Billingservicev1Invoice } from '../models/Billingservicev1Invoice';
import { HttpFile } from '../http/http';

/**
* ListInvoicesResponse
*/
export class Billingservicev1ListInvoicesResponse {
    /**
    * List of invoices
    */
    'invoices'?: Array<Billingservicev1Invoice>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "invoices",
            "baseName": "invoices",
            "type": "Array<Billingservicev1Invoice>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Billingservicev1ListInvoicesResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

