import { ItemType, useAppContext } from 'Contexts'
import { useEffect, useRef, useState } from 'react'

export const useLibraryFolderList = ({
  type,
  selectedItem,
}: {
  type: 'creatives' | 'audiences'
  selectedItem?: ItemType
}) => {
  // Used to hold list of ALL folders available
  const [folderList, setFolderList] = useState<string[]>([])
  const { audienceServiceApi, creativeServiceApi, currentOrg } = useAppContext()
  const fetchedFolderListOrgId = useRef<string | null>(null)

  useEffect(() => {
    const handlePopulateFolders = async () => {
      let currentNextPageToken: string | null | undefined = null
      let newFolderList: string[] = []
      if (type === 'audiences' && audienceServiceApi) {
        // populate list until there is no more pages (nextPageToken is '' or undefined)
        while (currentNextPageToken !== '') {
          const res = await audienceServiceApi.advertisingPlatformServiceListAudienceFolders(
            50, // we shouldnt see a lot of cases where there are more than 50 folders, but at least we can fetch them all here.
            currentNextPageToken || undefined, // pageToken
            undefined, // orderBy
            `org_id = "${currentOrg?.id}"`, // filter
          )
          currentNextPageToken = res.nextPageToken as string | null | undefined
          newFolderList = [
            ...newFolderList,
            ...(res.folders || []).map((folder) => folder.name || ''),
          ]
          setFolderList(newFolderList)
        }
      }
      if (type === 'creatives' && creativeServiceApi) {
        while (currentNextPageToken !== '') {
          const res = await creativeServiceApi.advertisingPlatformServiceListCreativeFolders(
            50,
            currentNextPageToken || undefined, // pageToken
            undefined, // orderBy
            `org_id = "${currentOrg?.id}"`, // filter
          )
          currentNextPageToken = res.nextPageToken as string | null | undefined
          newFolderList = [
            ...newFolderList,
            ...(res.folders || []).map((folder) => folder.name || ''),
          ]
          setFolderList(newFolderList)
        }
      }
    }

    const wasAlreadyCollectedForThisOrg =
      fetchedFolderListOrgId.current === currentOrg?.id

    // this should only populate if the user opens the preview panel once
    if (currentOrg?.id && !wasAlreadyCollectedForThisOrg && selectedItem) {
      handlePopulateFolders()
        .catch(() => {})
        .finally(() => {
          fetchedFolderListOrgId.current = currentOrg?.id || null
        })
    }
  }, [audienceServiceApi, creativeServiceApi, currentOrg, selectedItem, type])

  const addToFolderList = (folder: string) => {
    setFolderList((prev) => [...new Set([...prev, folder])])
  }

  return [folderList, addToFolderList] as const
}
