import {
  Button,
  Fa,
  Select,
  SelectOptionType,
  Text,
} from '@eltoro-ui/components'
import { iconButtonList } from 'Components/CreativeTypeSelector'
import { styleTailwind } from 'Helpers'
import {
  Creativeservicev1Creative,
  Targetjobservicev1Audience,
} from 'next-gen-sdk'
import { audienceUploadConfigs } from 'Pages/AudienceUpload/AudienceUploadConfigs'
import { AssetEditSearch } from './AssetEditSearch'
import { ModifiedOrderLineType } from './types'
import { useAppContext, useCampaignContext } from 'Contexts'

const HeaderLinkButton = styleTailwind(
  Button,
  'AssetEditHeader__HeaderLinkButton',
)

interface AssetEditHeaderType {
  selectedType?: SelectOptionType
  setSelectedType: React.Dispatch<
    React.SetStateAction<SelectOptionType | undefined>
  >
  setView: React.Dispatch<
    React.SetStateAction<'library' | 'create' | undefined>
  >
  modifiedOrderLines: ModifiedOrderLineType[]
}

export interface AssetEditHeaderAudiences extends AssetEditHeaderType {
  handleAddAudience: (
    newAudience: Targetjobservicev1Audience,
    orderLines?: {
      sourceOL?: ModifiedOrderLineType
      targetOL?: ModifiedOrderLineType
    },
  ) => void
  handleAddCreative?: never
}

export interface AssetEditHeaderCreatives extends AssetEditHeaderType {
  handleAddCreative: (
    newCreative: Creativeservicev1Creative,
    orderLine?: ModifiedOrderLineType,
  ) => void
  handleAddAudience?: never
}

export const AssetEditHeader = ({
  setView,
  selectedType,
  setSelectedType,
  handleAddAudience,
  handleAddCreative,
  modifiedOrderLines,
}: AssetEditHeaderAudiences | AssetEditHeaderCreatives) => {
  const { isAdmin } = useAppContext()
  const { isPolitical } = useCampaignContext()
  const options = handleAddAudience
    ? audienceUploadConfigs.reduce((acc: SelectOptionType[], conf) => {
        if (conf.disabled) return acc
        if (
          (!!modifiedOrderLines.find((ol) => ol.political) || isPolitical) &&
          conf.id === 'retargeting'
        ) {
          return acc
        }
        if (!isAdmin) {
          if (conf.adminOnly) return acc
        }
        return [
          ...acc,
          {
            value: conf.id,
            label: conf.title,
          },
        ]
      }, [])
    : iconButtonList.reduce((acc: SelectOptionType[], conf) => {
        if (conf.disabled) return acc
        if (
          (!!modifiedOrderLines.find((ol) => ol.political) || isPolitical) &&
          (conf.id === 'html5' || conf.id === 'native')
        ) {
          return acc
        }
        return [
          ...acc,
          {
            value: conf.id,
            label: conf.title,
          },
        ]
      }, [])
  return (
    <div className="AssetEditHeader__new-asset-header  laptopsmall:flex-wrap flex items-center gap-x-4 gap-y-3 pb-4 pr-1">
      <div className="laptopsmall:w-full mb-2 w-52">
        <Text className="text-l font-bold" tag="p">
          Bulk Edit
        </Text>
        <Text tag="p">
          Edit {handleAddAudience ? 'audiences' : 'creatives'} for the order
          line(s) below
        </Text>
      </div>
      {handleAddAudience && (
        <AssetEditSearch
          modifiedOrderLines={modifiedOrderLines}
          handleAddAudience={handleAddAudience}
          setSelectedType={setSelectedType}
          setView={setView}
        />
      )}
      {handleAddCreative && (
        <AssetEditSearch
          modifiedOrderLines={modifiedOrderLines}
          handleAddCreative={handleAddCreative}
          setSelectedType={setSelectedType}
          setView={setView}
        />
      )}

      <HeaderLinkButton
        kind="text"
        iconLeft={
          <Fa
            className="text-primary scale-75"
            icon="folders"
            size={3}
            type="duotone"
          />
        }
        onClick={() => setView('library')}
        className="laptopsmall:w-[45%] hover:text-primary-700 m-auto gap-2"
      >
        {handleAddAudience ? 'Audience' : 'Creative'} Library
      </HeaderLinkButton>
      <div className="laptopsmall:justify-center laptopsmall:w-[45%] flex flex-col gap-1">
        <label className="text-center">
          {`Click here to upload or ${
            handleAddAudience ? 'generate an audience' : 'create a creative'
          }`}
        </label>
        <div className="AssetEditHeader__asset-type-select flex items-center">
          <Select
            placeholder="Select type"
            clearButton={false}
            options={options}
            selectedOption={selectedType}
            onChange={(option) => setSelectedType(option)}
            maxHeight={200}
            className="laptopsmall:self-stretch desktop:min-w-[8.5rem]"
            optionsClassName="z-[60]"
            selectClassName="!rounded-tr-none !rounded-br-none !rounded-tl-3xl !rounded-bl-3xl !pl-5"
          />
          <HeaderLinkButton
            className="Button__upload-edit relative z-20 !rounded-tl-none !rounded-bl-none !rounded-tr-3xl !rounded-br-3xl"
            onClick={() => setView('create')}
          >
            Upload/Create
          </HeaderLinkButton>
        </div>
      </div>
    </div>
  )
}
