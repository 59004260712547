import { useEffect, useRef, useState } from 'react'
import { Button, Popover, Fa, TextHeader } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { styleTailwind, truncateMiddle } from 'Helpers'
import { TextInputWithErrorText } from 'Components'

const FolderButton = styleTailwind(
  Button,
  'FolderButton hover:!text-primary-600 hover:!bg-tint-khaki-100 gap-1',
)
const StackBtn = styleTailwind('div', 'StackBtn flex-1 StackedButtonIcon group')

export const FolderListButton = ({
  folders,
  onDeleteFolder,
  onAddFolder,
  onRemoveFromFolder,
  onRenameFolder,
  locked,
  hasDeployments,
  selectedFolderId,
  isFromChildOrg,
}: {
  folders: string[]
  onDeleteFolder?: () => void
  onAddFolder: (folder: string) => void
  onRemoveFromFolder?: () => void
  onRenameFolder?: (newName: string) => void
  locked?: boolean
  hasDeployments?: boolean
  selectedFolderId: string
  isFromChildOrg?: boolean
}) => {
  const [listOpen, setListOpen] = useState(false)
  const [addFolder, setAddFolder] = useState(false)
  const [renameFolder, setRenameFolder] = useState(false)
  const [renameFolderName, setRenameFolderName] = useState<string>(
    selectedFolderId,
  )
  const [newFolderName, setNewFolderName] = useState<string>('')
  const [selectedFolder, setSelectedFolder] = useState<string>(selectedFolderId)
  const [deletingFolder, setDeletingFolder] = useState(false)
  const folderOptionsRef = useRef<HTMLDivElement>(null)
  const folderOptionsButtonRef = useRef<HTMLButtonElement>(null)

  useOnClickOutside([folderOptionsRef, folderOptionsButtonRef], () => {
    setListOpen(false)
    setAddFolder(false)
    setDeletingFolder(false)
    setRenameFolder(false)
    setNewFolderName('')
    setRenameFolderName('')
    setSelectedFolder('')
  })

  const validCheck = (input: string) => {
    const regex = new RegExp(/^[\w\s_-]+$/, 'i')
    return regex.test(input)
  }

  const TruncateTTFolderName = truncateMiddle(selectedFolder, 25)

  useEffect(() => {
    if (selectedFolderId !== '' || onRemoveFromFolder || onDeleteFolder) {
      setSelectedFolder(selectedFolderId)
    }
    if (
      (renameFolder && selectedFolder !== '') ||
      (onRenameFolder && selectedFolder !== '')
    ) {
      setSelectedFolder(selectedFolderId)
    }
  }, [
    newFolderName,
    onAddFolder,
    onRemoveFromFolder,
    onRenameFolder,
    onDeleteFolder,
    renameFolder,
    selectedFolder,
    selectedFolderId,
  ])

  return (
    <div className="FolderListButton">
      <Popover
        attachId="MainAppContainer"
        className="FolderListButton__popover animate-slideright"
        position={['left']}
        align="start"
        content={
          <div
            className="FolderListButton__list bg-base flex flex-col overflow-y-auto overflow-x-visible rounded-lg"
            ref={folderOptionsRef}
          >
            <div className="FolderActions__wrap bg-tint-khaki-50 flex flex-col justify-center gap-2 px-2 pb-3">
              <div className="FolderActions flex items-start">
                {onRemoveFromFolder && (
                  <StackBtn
                    className="FolderListButton__remove-from hover:text-primary"
                    data-tooltip="right"
                    aria-label={`Click to remove file from ${
                      selectedFolder !== ''
                        ? `"${TruncateTTFolderName}" folder`
                        : 'folder'
                    }`}
                  >
                    <Button
                      iconCenter={
                        <Fa
                          icon="folder-minus"
                          size={4}
                          className="StackedButtonIcon__icon"
                        />
                      }
                      onClick={() => {
                        setAddFolder(false)
                        setListOpen(false)
                        onRemoveFromFolder()
                      }}
                    >
                      <span className="IconLabel">
                        <span className="leading-none">Remove from </span>
                        <strong className="leading-none">
                          {truncateMiddle(selectedFolder, 15)}
                        </strong>
                      </span>
                    </Button>
                  </StackBtn>
                )}
                {/* only show button if selected item has a folder filled */}
                {onRemoveFromFolder ? (
                  <StackBtn
                    className="FolderListButton__rename-folder hover:text-primary focus:text-primary flex-1"
                    data-tooltip="right"
                    aria-label={`Click to rename ${
                      selectedFolder !== ''
                        ? `"${TruncateTTFolderName}" folder`
                        : 'folder'
                    }`}
                  >
                    <Button
                      classNameWrap="FolderListButton__rename-folder"
                      className={`${renameFolder ? 'active' : ''}`}
                      iconCenter={
                        <Fa
                          icon="folder-gear"
                          size={4}
                          className="StackedButtonIcon__icon"
                        />
                      }
                      onClick={() => {
                        setAddFolder(false)
                        setRenameFolder(true)
                        setRenameFolderName(selectedFolderId)
                      }}
                    >
                      <span className="IconLabel">
                        <span className="leading-none">Rename </span>
                        <strong className="leading-none">
                          {truncateMiddle(selectedFolder, 15)}
                        </strong>
                      </span>
                    </Button>
                  </StackBtn>
                ) : null}
                {/* always need a button to add a new folder */}
                <StackBtn
                  className="FolderListButton__add-folder hover:text-primary focus:text-primary flex-1"
                  data-tooltip="left"
                  aria-label="Click to add file to a new folder"
                >
                  <Button
                    classNameWrap="FolderListButton__new-change-folder"
                    className={`FolderListButton ${addFolder ? 'active' : ''}`}
                    iconCenter={
                      <Fa
                        icon="folder-plus"
                        size={4}
                        className="StackedButtonIcon__icon"
                      />
                    }
                    onClick={() => {
                      setAddFolder(true)
                      setRenameFolder(false)
                    }}
                  >
                    <span className="IconLabel">
                      <span className="leading-none">New folder</span>
                    </span>
                  </Button>
                </StackBtn>
                {(onDeleteFolder || deletingFolder) && (
                  <StackBtn
                    className="FolderListButton__remove-folder text-danger-600 hover:text-primary"
                    data-tooltip="left"
                    aria-label={`Click to delete ${
                      selectedFolder !== ''
                        ? `"${TruncateTTFolderName}" folder`
                        : 'folder'
                    }`}
                  >
                    <Button
                      iconCenter={
                        <Fa
                          icon="folder-xmark"
                          size={4}
                          className="StackedButtonIcon__icon"
                        />
                      }
                      onClick={() => {
                        setListOpen(false)
                        onDeleteFolder && onDeleteFolder()
                        setDeletingFolder(false)
                      }}
                    >
                      <span className="IconLabel !text-danger">
                        <span className="leading-none">Delete </span>
                        <strong className="leading-none">
                          {truncateMiddle(selectedFolder, 15)}
                        </strong>
                      </span>
                    </Button>
                  </StackBtn>
                )}
              </div>
              {renameFolder ? (
                <div className="FolderListButton__rename-folder animate-slidedown flex items-center gap-2">
                  <TextInputWithErrorText
                    classNameWrap="inline-block mr-2"
                    value={renameFolderName}
                    onChange={(e) => {
                      setRenameFolderName(e.target.value)
                    }}
                    isValid={validCheck(renameFolderName)}
                    popoverContent={
                      <div className="FolderListButton__new-folder---popover-text">
                        Folder names must be alphanumeric (a-z, 0-9) and can
                        include dashes (-), spaces or underscores (_)
                      </div>
                    }
                  />
                  <Button
                    iconOnly={<Fa icon="floppy-disk" size={1} />}
                    onClick={() => {
                      setAddFolder(false)
                      setRenameFolder(false)
                      setListOpen(false)
                      onRenameFolder && onRenameFolder(renameFolderName)
                      setSelectedFolder(renameFolderName)
                    }}
                    disabled={!validCheck(renameFolderName)}
                  />

                  <Button
                    iconOnly={<Fa icon="times" size={1} />}
                    onClick={() => {
                      setRenameFolder(false)
                      setRenameFolderName('')
                    }}
                  />
                </div>
              ) : null}
              {addFolder ? (
                <div className="FolderListButton__new-folder-name animate-slidedown flex items-center gap-2">
                  <TextInputWithErrorText
                    classNameWrap="inline-block mr-2"
                    value={newFolderName}
                    placeholder="Folder name, letters/numbers only"
                    onChange={(e) => setNewFolderName(e.target.value)}
                    isValid={validCheck(newFolderName)}
                    popoverContent={
                      <div className="FolderListButton__new-folder---popover-text">
                        Folder names must be alphanumeric (a-z, 0-9) and can
                        include dashes (-), spaces or underscores (_)
                      </div>
                    }
                  />
                  <Button
                    iconOnly={<Fa icon="floppy-disk" size={1} />}
                    onClick={() => {
                      setAddFolder(false)
                      setListOpen(false)
                      onAddFolder(newFolderName)
                      setSelectedFolder(newFolderName)
                    }}
                    disabled={!validCheck(newFolderName)}
                  />
                  <Button
                    iconOnly={<Fa icon="times" size={1} />}
                    onClick={() => {
                      setAddFolder(false)
                      setNewFolderName('')
                    }}
                  />
                </div>
              ) : null}
            </div>
            {folders.filter((f) => f).length > 0 && (
              <ul className="FoldersList border-t-thin border-tint-gray-200 relative z-20 flex max-h-96 flex-col pb-10">
                <TextHeader
                  className="SectionLabel !text-ml font-regular border-b-thin border-tint-gray-200 mx-auto mb-4 flex w-auto flex-1 gap-2 px-10 pt-8 pb-4 text-center uppercase leading-none"
                  type={5}
                  aria-label="Click on folder to move item"
                  data-tooltip="top"
                >
                  <span>Move to folder</span>
                  <Fa className="text-primary" icon="caret-down" size={1} />
                </TextHeader>

                {folders.map((folder) => {
                  if (folder === '') return null
                  return (
                    <li
                      key={folder}
                      className="FoldersList__existing-folder relative z-20 flex items-center px-2"
                      aria-label={`Move item to "${folder}"`}
                      data-tooltip="top"
                    >
                      <FolderButton
                        classNameWrap="FoldersList__existing-folder-iconwrap h-full py-2"
                        kind="text-icon"
                        iconLeft={<Fa icon="folder" size={1} />}
                        onClick={() => {
                          onAddFolder(folder)
                          setSelectedFolder(folder)
                          setListOpen(false)
                        }}
                      >
                        {truncateMiddle(folder, 52)}
                      </FolderButton>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        }
        isOpen={listOpen}
      >
        <div
          className="FolderListFolderButton__move-btn-popover-trigger flex"
          data-tooltip="top"
          aria-label={(() => {
            if (locked) return 'Audience is locked'
            if (hasDeployments)
              return 'Folder cannot be changed when audience has deployments'
            if (isFromChildOrg)
              return "Please edit folder of this asset from parent org's library"
            if (onRemoveFromFolder) return 'Click to change folder'
            return 'Click to add to a folder'
          })()}
        >
          <Button
            className={`FolderListFolderButton__move-btn flex-1 ${
              listOpen ? `active` : ''
            }`}
            classNameWrap="FolderListFolderButton__move-btn--icon-wrap"
            iconLeft={<Fa icon="folder-gear" size={1} />}
            buttonRef={folderOptionsButtonRef}
            onClick={() => {
              setListOpen(!listOpen)
              if (selectedFolderId !== '') {
                setSelectedFolder(selectedFolderId)
              }
              if (newFolderName !== '') {
                setSelectedFolder(newFolderName)
              }
              setSelectedFolder('')
            }}
            disabled={locked || hasDeployments || isFromChildOrg}
          >
            Folder options
          </Button>
        </div>
      </Popover>
    </div>
  )
}
