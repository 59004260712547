import { useState } from 'react'
import {
  Modal,
  TabType,
  Tabs,
  TextHeader,
  showErrorMessage,
  showInfoAlert,
  showSuccessMessage,
} from '@eltoro-ui/components'
import {
  Campaignservicev1OrderLine,
  Targetjobservicev1Audience,
  V1ProductType,
} from 'next-gen-sdk'
import {
  PolygonMap,
  RetargetingPixel,
  WarningModal,
  AudienceUploadStepper,
} from 'Components'
import { AudienceLibraryPage, audienceUploadConfigs } from 'Pages'
import { useAppContext } from 'Contexts'
import './EditOLAudiences.scss'
import {
  DetermineIfAllowedToAttachAudience,
  getHighestCPMProductType,
  styleTailwind,
  userFriendlyAudienceName,
} from 'Helpers'
import { ETIcon } from 'Assets'

export const EditOLAudiences: React.FC<{
  orderLine: Campaignservicev1OrderLine
  onClose: () => void
  refreshTableOL: () => void
  startingState?: 'library' | 'create'
}> = ({ orderLine, onClose, startingState, refreshTableOL }) => {
  const [addError, setAddError] = useState<string>()

  const { campaignServiceApi, currentOrg, isAdmin } = useAppContext()

  // note- if we change this to allow any audiences to be added from here, may still need to block adding retargeting to political
  const filterByProductType = undefined
  const handleAddAudience = (audience: Targetjobservicev1Audience) => {
    if (!campaignServiceApi || !orderLine.id || !currentOrg?.id) return
    const allowedToAttach = DetermineIfAllowedToAttachAudience(
      orderLine,
      audience,
      isAdmin,
    )
    showInfoAlert(
      'Adding audience...',
      'Your audience is being added to the order line',
    )
    if (allowedToAttach) {
      const audiences =
        audience.type === 'AUDIENCE_TYPE_VR' && audience.audiences
          ? audience.audiences.map(({ id }) => ({ id, exclude: false }))
          : [{ id: audience.id, exclude: false }]

      campaignServiceApi
        .advertisingPlatformServiceBatchAddAudiences(orderLine.id, {
          orgId: currentOrg.id,
          audiences,
        })
        .then(() => {
          showSuccessMessage(
            `${audiences.length > 1 ? 'Audiences' : 'Audience'} Attached`,
            `Your ${
              audiences.length > 1 ? 'audiences were' : 'audience was'
            } attached to the order line`,
          )
          refreshTableOL()
        })
        .catch(() =>
          showErrorMessage(
            `Error adding audience${audiences.length > 1 ? 's' : ''}`,
            '',
          ),
        )
    }
    if (!allowedToAttach) {
      showErrorMessage(
        'Unable to Attach',
        `Attaching product type "
        ${userFriendlyAudienceName(audience.productType || '')}" is
        not allowed on this order line`,
      )
    }
  }
  const AudienceCat = styleTailwind(
    'span',
    'AudienceHeader__category whitespace-nowrap',
  )

  const handleRemoveAudience = (audience: Targetjobservicev1Audience) => {
    if (!campaignServiceApi || !orderLine.id || !currentOrg?.id || !audience.id)
      return
    showInfoAlert(
      'Removing audience...',
      'Your audience is being removed from the order line',
    )
    const audienceIds =
      audience.type === 'AUDIENCE_TYPE_VR' && audience.audiences
        ? audience.audiences.reduce((acc: string[], curr) => {
            if (!curr.id) return acc
            return [...acc, curr.id]
          }, [])
        : [audience.id]
    campaignServiceApi
      .advertisingPlatformServiceBatchRemoveAudiences(orderLine.id, {
        orgId: currentOrg.id,
        audienceIds,
      })
      .then(() => {
        showSuccessMessage(
          `${audienceIds.length > 1 ? 'Audiences' : 'Audience'} Removed`,
          `Your ${
            audienceIds.length > 1 ? 'audiences were' : 'audience was'
          } removed from the order line`,
        )
        refreshTableOL()
      })
      .catch(() =>
        showErrorMessage(
          `Error removing audience${audienceIds.length > 1 ? 's' : ''}`,
          '',
        ),
      )
  }

  return (
    <Modal
      className="EditOLAudiences min-h-[85vh] min-w-[85vw] p-4"
      offClick={onClose}
      header={<TextHeader type={3}>Add Audiences: {orderLine.name}</TextHeader>}
    >
      <div className="EditOLAudiences__selections mb-4 mt-4 grid grid-cols-3 gap-2 px-5"></div>
      {startingState === 'library' && (
        <AudienceLibraryPage
          selectedAudiences={orderLine.audiences}
          filterByProductType={filterByProductType && [filterByProductType]}
          onAddAudienceToAttach={handleAddAudience}
          onRemoveAudienceFromAttach={handleRemoveAudience}
        />
      )}
      {startingState === 'create' && (
        <>
          {(() => {
            if (!currentOrg) return null
            const productList = getHighestCPMProductType(orderLine, currentOrg)
            if (productList.products) {
              const tabs: TabType[] = []
              const { products } = productList
              if (
                (products as V1ProductType[]).includes(
                  'PRODUCT_TYPE_DIGITAL_CANVASSING',
                ) &&
                !isAdmin
              ) {
                return (
                  <Tabs
                    tabs={[
                      {
                        id: 'dc',
                        label: (
                          <>
                            <ETIcon icon="digital-canvassing" />
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Location
                              </span>
                              <AudienceCat>Digital Canvassing</AudienceCat>
                            </span>
                          </>
                        ),
                        component: (
                          <AudienceUploadStepper
                            audienceType="dc"
                            onAddAudienceToAttach={handleAddAudience}
                            useInCampaignButton={false}
                          />
                        ),
                      },
                    ]}
                  ></Tabs>
                )
              }
              if (
                (products as V1ProductType[]).includes(
                  'PRODUCT_TYPE_VENUE_REPLAY',
                ) &&
                !isAdmin
              ) {
                return (
                  <Tabs
                    tabs={[
                      {
                        id: 'vr',
                        label: (
                          <>
                            <ETIcon icon="venue-replay" />
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Behavioral
                              </span>
                              <AudienceCat>Venue Replay</AudienceCat>
                            </span>
                          </>
                        ),
                        component: (
                          <PolygonMap
                            vr
                            onAddAudienceToAttach={handleAddAudience}
                          />
                        ),
                      },
                    ]}
                  ></Tabs>
                )
              }
              if (
                (products as V1ProductType[]).includes(
                  'PRODUCT_TYPE_NEW_MOVERS',
                ) &&
                !isAdmin
              ) {
                return (
                  <Tabs
                    tabs={[
                      {
                        id: 'movers',
                        label: (
                          <>
                            <ETIcon
                              className="AudienceTypeSelector__tabs--icon"
                              icon="movers"
                            />
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Behavioral
                              </span>
                              <AudienceCat>Digital New Mover</AudienceCat>
                            </span>
                          </>
                        ),
                        component: (
                          <AudienceUploadStepper
                            audienceType="new-mover"
                            onAddAudienceToAttach={handleAddAudience}
                          />
                        ),
                      },
                    ]}
                  ></Tabs>
                )
              }
              products.map((currentProduct) => {
                if (
                  (currentProduct as V1ProductType) ===
                    'PRODUCT_TYPE_MAP_POLYGON' &&
                  products.length === 1
                ) {
                  tabs.push({
                    id: 'map',
                    label: (
                      <>
                        <ETIcon icon="map-polygon" />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Location
                          </span>
                          <AudienceCat>Map Polygon</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <PolygonMap
                        vr={false}
                        onAddAudienceToAttach={handleAddAudience}
                      />
                    ),
                  })
                }
                if (
                  (currentProduct as V1ProductType) ===
                  'PRODUCT_TYPE_VENUE_REPLAY'
                ) {
                  tabs.push({
                    id: 'vr',
                    label: (
                      <>
                        <ETIcon icon="venue-replay" />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Behavioral
                          </span>
                          <AudienceCat>Venue Replay</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <PolygonMap
                        vr
                        onAddAudienceToAttach={handleAddAudience}
                      />
                    ),
                  })
                }
                if (
                  (currentProduct as V1ProductType) ===
                  'PRODUCT_TYPE_NEW_MOVERS'
                ) {
                  tabs.push({
                    id: 'new-mover',
                    label: (
                      <>
                        <ETIcon
                          className="AudienceTypeSelector__tabs--icon"
                          icon="movers"
                        />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Behavioral
                          </span>
                          <AudienceCat>Digital New Mover</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <AudienceUploadStepper
                        audienceType="new-mover"
                        onAddAudienceToAttach={handleAddAudience}
                      />
                    ),
                  })
                }
                if (
                  (currentProduct as V1ProductType) ===
                  'PRODUCT_TYPE_DIGITAL_CANVASSING'
                ) {
                  tabs.push({
                    id: 'dc',
                    label: (
                      <>
                        <ETIcon icon="digital-canvassing" />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Location
                          </span>
                          <AudienceCat>Digital Canvassing</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <AudienceUploadStepper
                        audienceType="dc"
                        onAddAudienceToAttach={handleAddAudience}
                        useInCampaignButton={false}
                      />
                    ),
                  })
                }
                if (
                  (currentProduct as V1ProductType) ===
                  'PRODUCT_TYPE_IP_TARGETING'
                ) {
                  const uploadConfigs = audienceUploadConfigs.filter(
                    ({ id }) => id === 'address' || id === 'zip' || id === 'ip',
                  )
                  tabs.push({
                    id: 'Ip Targeting',
                    label: (
                      <>
                        <ETIcon
                          className="AudienceTypeSelector__tabs--icon"
                          icon="sale-doc"
                        />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Address
                          </span>
                          <AudienceCat>IP Address List</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <Tabs
                        tabs={uploadConfigs.map((config) => {
                          return {
                            id: config.id,
                            label: (
                              <>
                                {config.icon}
                                <span className="flex flex-col">
                                  <div className="EditOLTagets__tabs-category Tabs-category">
                                    {config.tagLabel}
                                  </div>
                                  <span>{config.title}</span>
                                </span>
                              </>
                            ),
                            component: (
                              <AudienceUploadStepper
                                audienceType={config.id}
                                onAddAudienceToAttach={handleAddAudience}
                                useInCampaignButton={false}
                              />
                            ),
                          }
                        })}
                      />
                    ),
                  })
                  tabs.push({
                    id: 'map',
                    label: (
                      <>
                        <ETIcon icon="map-polygon" />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Location
                          </span>
                          <AudienceCat>Map Polygon</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <PolygonMap
                        vr={false}
                        onAddAudienceToAttach={handleAddAudience}
                      />
                    ),
                  })
                }
                return []
              })
              return <Tabs tabs={tabs}></Tabs>
            }
          })()}
        </>
      )}
      {addError && (
        <WarningModal onConfirm={() => setAddError(undefined)}>
          {addError}
        </WarningModal>
      )}
    </Modal>
  )
}
