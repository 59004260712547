import { useState, useContext, useEffect, createContext, useRef } from 'react'
import { useBrowser } from '@eltoro-ui/hooks'
import { showErrorMessage, showSuccessMessage } from '@eltoro-ui/components'
import { V1Notification } from 'next-gen-sdk'
import { getSanitizedHTML } from 'Helpers'
import { useAppContext } from './AppContext'

type NotificationContextType = {
  alertsList: V1Notification[]
  createNotification: (data: V1Notification) => Promise<void>
  deleteNotification: (data: V1Notification) => Promise<void>
  updateNotification: (data: V1Notification) => void
  loading: boolean
  newNotifications: V1Notification[]
}

export const notificationContextBaseState: NotificationContextType = {
  alertsList: [],
  createNotification: async () => {},
  deleteNotification: async () => {},
  updateNotification: () => {},
  loading: false,
  newNotifications: [],
}

export const NotificationContext = createContext<NotificationContextType>(
  notificationContextBaseState,
)

export const useNotificationContext = () => {
  const ctx = useContext(NotificationContext)
  if (!ctx)
    throw new Error(
      'useNotificationContext must be used within the NotificationContextProvider',
    )
  return ctx
}

type NotificationContextProps = {
  children?: React.ReactNode
}

export const NotificationContextProvider: React.FC<NotificationContextProps> = ({
  children,
}) => {
  const Browser = useBrowser()
  const [alertsList, setAlertsList] = useState<V1Notification[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [newNotifications, setNewNotifications] = useState<V1Notification[]>([])
  const { notificationsApi } = useAppContext()
  const alreadyFetchedAlerts = useRef(false)

  useEffect(() => {
    const filteredNewAlerts = (
      newAlerts: V1Notification[],
    ): V1Notification[] => {
      const currentTime = new Date()
      const days = 6
      const daysTime = new Date(
        currentTime.getTime() - days * 24 * 60 * 60 * 1000,
      )

      return newAlerts.filter((alert) => {
        if (alert.updateTime) {
          const alertTime = new Date(alert.updateTime)
          return alertTime > daysTime
        }
        return null
      })
    }
    const fetchData = async () => {
      notificationsApi
        ?.advertisingPlatformServiceGetNotifications()
        .then(({ notifications }) => {
          if (!notifications) return
          const sortedNotifications = notifications.sort(
            (a, b) => b.updateTime!.getTime() - a.updateTime!.getTime(),
          )
          const alertsOnly = sortedNotifications.filter(
            (notification) => notification.global && notification.active,
          )
          setAlertsList(alertsOnly)
          setNewNotifications(filteredNewAlerts(sortedNotifications))
        })
        .catch(() => {
          showErrorMessage(
            'Error Getting Notifications...',
            `Please try again later!`,
          )
        })
        .finally(() => {
          setLoading(false)
          alreadyFetchedAlerts.current = true
        })
    }

    if (notificationsApi && !alreadyFetchedAlerts.current) fetchData()
  }, [notificationsApi])

  const createNotification = async (data: V1Notification) => {
    if (!notificationsApi) return
    const sanitizedMessage = getSanitizedHTML(data.message as string, Browser)

    try {
      const createMessage = await notificationsApi?.advertisingPlatformServiceCreateNotification(
        {
          ...data,
          message: sanitizedMessage, // Sanitized message, allows tags
        },
      )
      if (createMessage) {
        setAlertsList([createMessage, ...alertsList])
        showSuccessMessage(
          `Added "${createMessage.title}"`,
          'The new alert should be on the list.',
        )
      } else {
        console.error('Getting an error from the API') // Handle no response
      }
    } catch (error) {
      showErrorMessage(
        'Error saving alert',
        `Error, ${error}, saving alert. Please try again later`,
      )
    }
  }

  const deleteNotification = async (data: V1Notification) => {
    if (!notificationsApi || !data.id) return
    notificationsApi
      ?.advertisingPlatformServiceDeleteNotification(data.id!)
      .then(() => {
        setAlertsList(
          alertsList.filter((deleteItem) => deleteItem.id !== data.id),
        )
        showSuccessMessage(
          `"${data.title}" is deleted`,
          "Refresh, if the alert isn't deleted",
        )
      })
      .catch(() =>
        showErrorMessage(
          `Error deleting ${data.title}`,
          'Please try again later',
        ),
      )
  }

  const updateNotification = (data: V1Notification) => {
    if (!notificationsApi || !data.id) return
    const sanitizedMessage = getSanitizedHTML(data.message as string, Browser)
    notificationsApi
      ?.advertisingPlatformServiceUpdateNotification(data.id, {
        title: data.title,
        type: data.type,
        createTime: data.createTime,
        updateTime: data.updateTime,
        message: sanitizedMessage,
        userId: data.userId,
      })
      .then(() => {
        setAlertsList(alertsList.map((i) => (i.id === data.id ? data : i)))
        showSuccessMessage(
          `"${data.title}" is updated`,
          "Refresh page, if the alert isn't updated",
        )
      })
      .catch(() =>
        showErrorMessage('Error updating alert', 'Please try again later'),
      )
  }

  const contextValues = {
    alertsList,
    createNotification,
    deleteNotification,
    updateNotification,
    loading,
    newNotifications,
  }

  return (
    <NotificationContext.Provider value={contextValues}>
      {children}
    </NotificationContext.Provider>
  )
}
