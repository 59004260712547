/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetorchestratorv2FileType } from '../models/Targetorchestratorv2FileType';
import { Targetorchestratorv2Location } from '../models/Targetorchestratorv2Location';
import { V1DataProduct } from '../models/V1DataProduct';
import { V1DataSource } from '../models/V1DataSource';
import { V2TargetJob } from '../models/V2TargetJob';
import { V2TargetStatus } from '../models/V2TargetStatus';
import { HttpFile } from '../http/http';

/**
* The object that holds all info about a Target.
*/
export class V2Target {
    /**
    * The Target's ID.
    */
    'id'?: string;
    /**
    * The ID of the Org to which the Target ID associated.
    */
    'orgId'?: string;
    /**
    * The time the Target was created.
    */
    'createTime'?: Date;
    /**
    * The last update timestamp of the Target.
    */
    'updateTime'?: Date;
    /**
    * The deletion timestamp of the Target.
    */
    'deleteTime'?: Date;
    /**
    * The time the Target was archived.
    */
    'archiveTime'?: Date;
    /**
    * The time the Target was expired.
    */
    'expireTime'?: Date;
    /**
    * The time the Target was purged.
    */
    'purgeTime'?: Date;
    'status'?: V2TargetStatus;
    'fileType'?: Targetorchestratorv2FileType;
    /**
    * An array of Location objects that tells info about the target files uploaded.
    */
    'locations'?: Array<Targetorchestratorv2Location>;
    /**
    * An array of Audience objects that are attached to the Target.
    */
    'audiences'?: Array<V2TargetJob>;
    /**
    * The Target's RefID.
    */
    'refId'?: string;
    'dataSource'?: V1DataSource;
    /**
    * Whether the Target's source file has a header or not.
    */
    'hasHeader'?: boolean;
    /**
    * The folder that the target resides in.
    */
    'folder'?: string;
    'dataProduct'?: V1DataProduct;
    /**
    * The data sources of the uploaded file. This should be used if the file was created from multiple data sets and then the results were combined. This is not a required field and if both the data_source field and the data_sources fields exist, they will be combined.
    */
    'dataSources'?: Array<V1DataSource>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "archiveTime",
            "baseName": "archive_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "expireTime",
            "baseName": "expire_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "purgeTime",
            "baseName": "purge_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V2TargetStatus",
            "format": ""
        },
        {
            "name": "fileType",
            "baseName": "file_type",
            "type": "Targetorchestratorv2FileType",
            "format": ""
        },
        {
            "name": "locations",
            "baseName": "locations",
            "type": "Array<Targetorchestratorv2Location>",
            "format": ""
        },
        {
            "name": "audiences",
            "baseName": "audiences",
            "type": "Array<V2TargetJob>",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "dataSource",
            "baseName": "data_source",
            "type": "V1DataSource",
            "format": ""
        },
        {
            "name": "hasHeader",
            "baseName": "has_header",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "folder",
            "baseName": "folder",
            "type": "string",
            "format": ""
        },
        {
            "name": "dataProduct",
            "baseName": "data_product",
            "type": "V1DataProduct",
            "format": ""
        },
        {
            "name": "dataSources",
            "baseName": "data_sources",
            "type": "Array<V1DataSource>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V2Target.attributeTypeMap;
    }

    public constructor() {
    }
}

