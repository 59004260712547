/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1AdType } from '../models/Campaignservicev1AdType';
import { Campaignservicev1Audience } from '../models/Campaignservicev1Audience';
import { Campaignservicev1Creative } from '../models/Campaignservicev1Creative';
import { Campaignservicev1Note } from '../models/Campaignservicev1Note';
import { Deployservicev3Destination } from '../models/Deployservicev3Destination';
import { GoogletypeMoney } from '../models/GoogletypeMoney';
import { OrderLineDeployMetadata } from '../models/OrderLineDeployMetadata';
import { V1AuditConditions } from '../models/V1AuditConditions';
import { V1CostRange } from '../models/V1CostRange';
import { V1OrderLineCampaign } from '../models/V1OrderLineCampaign';
import { V1OrderLineStatus } from '../models/V1OrderLineStatus';
import { V1PoliticalFields } from '../models/V1PoliticalFields';
import { V1Review } from '../models/V1Review';
import { V1State } from '../models/V1State';
import { V1TemplateType } from '../models/V1TemplateType';
import { V3DeploymentDestinationConfigurations } from '../models/V3DeploymentDestinationConfigurations';
import { V3SegmentConfig } from '../models/V3SegmentConfig';
import { HttpFile } from '../http/http';

/**
* Order lines are unique to El Toro in that they are similar to the ad industry usage of the term “campaign”.
*/
export class Campaignservicev1OrderLine {
    /**
    * ID of the Order Line.
    */
    'id'?: string;
    /**
    * Name of the Order Line.
    */
    'name'?: string;
    /**
    * Client reference ID of the Order Line.
    */
    'refId'?: string;
    /**
    * Time the Order Line will start serving.
    */
    'startTime'?: Date;
    /**
    * Time the Order Line will stop serving.
    */
    'endTime'?: Date;
    /**
    * The amount of impressions the Order Line will serve.
    */
    'impressions'?: number;
    /**
    * The minimum impressions of the OrderLine.
    */
    'minimumImpressions'?: number;
    /**
    * The amount of impressions the Order Line will serve per day.
    */
    'impressionsPerDay'?: number;
    /**
    * The amount of impressions adops is giving to the orderline for free.
    */
    'freeImpressions'?: number;
    /**
    * The URL the user will be redirected to if the creative is clicked.
    */
    'clickThroughUrl'?: string;
    'prepay'?: boolean;
    'politicalFields'?: V1PoliticalFields;
    'jobId'?: string;
    'poId'?: string;
    'segmentConfig'?: V3SegmentConfig;
    'templateType'?: V1TemplateType;
    'deploymentDestination'?: Deployservicev3Destination;
    'stepFunction'?: string;
    /**
    * Organization ID the Order Line is associated to.
    */
    'orgId'?: string;
    'campaign'?: V1OrderLineCampaign;
    /**
    * Time the Order Line was created at.
    */
    'createTime'?: Date;
    'updateTime'?: Date;
    /**
    * Time the Order Line was deleted.
    */
    'deleteTime'?: Date;
    /**
    * The time the OrderLine was archived.
    */
    'archiveTime'?: Date;
    'status'?: V1OrderLineStatus;
    'state'?: V1State;
    /**
    * Reason the orderline is in the state or status it is in.
    */
    'reason'?: string;
    'firstDeployTime'?: Date;
    'lastDeployTime'?: Date;
    'firstDeployUserId'?: string;
    'lastDeployUserId'?: string;
    'deployMetadata'?: OrderLineDeployMetadata;
    /**
    * Whether or not an order line is part of a political campaign.
    */
    'political'?: boolean;
    'adType'?: Campaignservicev1AdType;
    'costRange'?: V1CostRange;
    'auditConditions'?: V1AuditConditions;
    'deploymentDestinationConfiguration'?: V3DeploymentDestinationConfigurations;
    /**
    * Creatives associated to the Order Line.
    */
    'creatives'?: Array<Campaignservicev1Creative>;
    /**
    * Audiences associated to the Order Line.
    */
    'audiences'?: Array<Campaignservicev1Audience>;
    'locked'?: boolean;
    'notes'?: Array<Campaignservicev1Note>;
    'paid'?: boolean;
    'cpms'?: { [key: string]: GoogletypeMoney; };
    'review'?: V1Review;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "startTime",
            "baseName": "start_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endTime",
            "baseName": "end_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "impressions",
            "baseName": "impressions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "minimumImpressions",
            "baseName": "minimum_impressions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "impressionsPerDay",
            "baseName": "impressions_per_day",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "freeImpressions",
            "baseName": "free_impressions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "clickThroughUrl",
            "baseName": "click_through_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "prepay",
            "baseName": "prepay",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "politicalFields",
            "baseName": "political_fields",
            "type": "V1PoliticalFields",
            "format": ""
        },
        {
            "name": "jobId",
            "baseName": "job_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "poId",
            "baseName": "po_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentConfig",
            "baseName": "segment_config",
            "type": "V3SegmentConfig",
            "format": ""
        },
        {
            "name": "templateType",
            "baseName": "template_type",
            "type": "V1TemplateType",
            "format": ""
        },
        {
            "name": "deploymentDestination",
            "baseName": "deployment_destination",
            "type": "Deployservicev3Destination",
            "format": ""
        },
        {
            "name": "stepFunction",
            "baseName": "step_function",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaign",
            "baseName": "campaign",
            "type": "V1OrderLineCampaign",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "archiveTime",
            "baseName": "archive_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1OrderLineStatus",
            "format": ""
        },
        {
            "name": "state",
            "baseName": "state",
            "type": "V1State",
            "format": ""
        },
        {
            "name": "reason",
            "baseName": "reason",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstDeployTime",
            "baseName": "first_deploy_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "lastDeployTime",
            "baseName": "last_deploy_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "firstDeployUserId",
            "baseName": "first_deploy_user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastDeployUserId",
            "baseName": "last_deploy_user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "deployMetadata",
            "baseName": "deploy_metadata",
            "type": "OrderLineDeployMetadata",
            "format": ""
        },
        {
            "name": "political",
            "baseName": "political",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "adType",
            "baseName": "ad_type",
            "type": "Campaignservicev1AdType",
            "format": ""
        },
        {
            "name": "costRange",
            "baseName": "cost_range",
            "type": "V1CostRange",
            "format": ""
        },
        {
            "name": "auditConditions",
            "baseName": "audit_conditions",
            "type": "V1AuditConditions",
            "format": ""
        },
        {
            "name": "deploymentDestinationConfiguration",
            "baseName": "deployment_destination_configuration",
            "type": "V3DeploymentDestinationConfigurations",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<Campaignservicev1Creative>",
            "format": ""
        },
        {
            "name": "audiences",
            "baseName": "audiences",
            "type": "Array<Campaignservicev1Audience>",
            "format": ""
        },
        {
            "name": "locked",
            "baseName": "locked",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "notes",
            "baseName": "notes",
            "type": "Array<Campaignservicev1Note>",
            "format": ""
        },
        {
            "name": "paid",
            "baseName": "paid",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "cpms",
            "baseName": "cpms",
            "type": "{ [key: string]: GoogletypeMoney; }",
            "format": ""
        },
        {
            "name": "review",
            "baseName": "review",
            "type": "V1Review",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Campaignservicev1OrderLine.attributeTypeMap;
    }

    public constructor() {
    }
}

