/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetorchestratorv2Column } from '../models/Targetorchestratorv2Column';
import { Targetorchestratorv2FileType } from '../models/Targetorchestratorv2FileType';
import { V1DataProduct } from '../models/V1DataProduct';
import { V1DataSource } from '../models/V1DataSource';
import { HttpFile } from '../http/http';

/**
* The info about the source file being uploaded to create a Target.
*/
export class Targetorchestratorv2FileMetaData {
    /**
    * The name of the source file.
    */
    'filename': string;
    /**
    * Id of the org to which this target belongs
    */
    'orgId'?: string;
    'fileType'?: Targetorchestratorv2FileType;
    'dataSource'?: V1DataSource;
    /**
    * Whether the file has a header or not.
    */
    'hasHeader'?: boolean;
    /**
    * An array of Column objects that define what each column in the file contains.
    */
    'columns': Array<Targetorchestratorv2Column>;
    'targetId'?: string;
    'dataProduct'?: V1DataProduct;
    /**
    * The data sources of the uploaded file. This should be used if the file was created from multiple data sets and then the results were combined. This is not a required field and if both the data_source field and the data_sources fields exist, they will be combined.
    */
    'dataSources'?: Array<V1DataSource>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "filename",
            "baseName": "filename",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "fileType",
            "baseName": "file_type",
            "type": "Targetorchestratorv2FileType",
            "format": ""
        },
        {
            "name": "dataSource",
            "baseName": "data_source",
            "type": "V1DataSource",
            "format": ""
        },
        {
            "name": "hasHeader",
            "baseName": "has_header",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "columns",
            "baseName": "columns",
            "type": "Array<Targetorchestratorv2Column>",
            "format": ""
        },
        {
            "name": "targetId",
            "baseName": "target_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "dataProduct",
            "baseName": "data_product",
            "type": "V1DataProduct",
            "format": ""
        },
        {
            "name": "dataSources",
            "baseName": "data_sources",
            "type": "Array<V1DataSource>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Targetorchestratorv2FileMetaData.attributeTypeMap;
    }

    public constructor() {
    }
}

