import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Fa,
  Modal,
  TextHeader,
  Text,
  DateRangePicker,
  FormItem,
  RadioButton,
  RadioButtonGroup,
  TextInput,
  showErrorMessage,
} from '@eltoro-ui/components'
import {
  Campaignservicev1OrderLine,
  Campaignservicev1Campaign,
  Creativeservicev1Creative,
  V1PoliticalFields,
} from 'next-gen-sdk'
import {
  CollapseButton,
  OLTableUpdateRow,
  PermalinkButton,
  ReportModal,
} from 'Components'
import { useAppContext } from 'Contexts'
import { CreativeResType } from 'types'
import { OrderLineEventHistory } from './OrderLineEventHistory'
import './ExpansionHeader.scss'
import { useNavigate } from 'react-router-dom'
import { ApiFactory } from 'apiFactory'
import dayjs from 'dayjs'
import { CampaignSelect } from './CampaignSelect/CampaignSelect'
import { styleTailwind } from 'Helpers'
import classNames from 'classnames'
import { SupportRequestModal } from 'Components/SupportRequestModal'
import { ETIcon } from 'Assets'
import { OrderLineNotes } from './OrderLineNotes'
import { IMPRESSION_MIN } from 'Components/OrderLineEditWrapper/components/shared'

const CampaignLabel = styleTailwind(
  'div',
  'Campaign__label flex flex-1 flex-col leading-none justify-start max-w-14 w-[9vw]',
)

const IconWrap = styleTailwind(
  'figure',
  'Campaign__icon-wrap flex items-center justify-center h-10 w-10',
)

const CampaignLabelWrap = styleTailwind(
  'div',
  'Campaign__label-wrap flex leading-none gap-3 items-center',
)

const CampaignInputWrap = styleTailwind(
  'div',
  'Campaign__input-wrap flex flex-1 leading-none items-start w-2/3',
)

const CampaignDetailRow = styleTailwind(
  'div',
  'Campaign__detail-row flex gap-[2.5vw] laptop:flex-1 desktop:pr-[2vw] desktop:pt-[2vw] justify-start w-full laptop:px-4',
)

export const ExpansionHeader = ({
  type,
  icon,
  name,
  isCollapsed,
  handleCollapse,
  orderline,
  campaignOrderlines,
  campaign,
  collapseIconName,
  setRefreshOL,
  handleUpdateRow,
}: {
  type: 'orderLine' | 'campaign'
  icon?: JSX.Element
  name: string
  isCollapsed: boolean
  handleCollapse: () => void
  orderline?: Campaignservicev1OrderLine
  campaignOrderlines?: Campaignservicev1OrderLine[]
  campaign?: Campaignservicev1Campaign
  collapseIconName?: string
  setRefreshOL?: React.Dispatch<React.SetStateAction<boolean>>
  handleUpdateRow?: OLTableUpdateRow
}) => {
  const {
    creativeServiceApi,
    currentOrg,
    roles,
    campaignServiceApi,
    isOnHold,
    isAdmin,
  } = useAppContext()
  const [showReport, setShowReport] = useState(false)
  const [cloneModal, setCloneModal] = useState(false)
  const [showSelect, setShowSelect] = useState(false)
  // LockModal
  const [lockModal, setLockModal] = useState(false)
  const [supportModal, setSupportModal] = useState(false)
  //Campaingn Creation
  const [showCampaignCreator, setShowCampaignCreator] = useState(false)
  const [political, setPolitical] = useState(orderline?.political || false)
  const [campName, setCampName] = useState('')
  const now = new Date()
  const isDisabled = campName?.length <= 1 || (!currentOrg && !campName)

  const [calcStartDate, setCalcStartDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate()),
  )

  const [calcEndDate, setCalcEndDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() + 14),
  )

  const lengthLimit = campName?.length > 255

  const [creatives, setCreatives] = useState<CreativeResType[]>([])
  const navigate = useNavigate()

  const hasDeployed = [
    'ORDERLINE_STATUS_DEPLOYED',
    'ORDERLINE_STATUS_SERVING',
    'ORDERLINE_STATUS_COMPLETED',
    'ORDERLINE_STATUS_ARCHIVED',
    'ORDERLINE_STATUS_PAUSED',
    'ORDERLINE_STATUS_ERRORED_AND_SERVING',
  ].includes(orderline?.status || '')

  const pastStartDate =
    orderline?.startTime &&
    orderline?.startTime.getTime() <= new Date().getTime()

  useEffect(() => {
    const creativePromises =
      (orderline?.creatives &&
        orderline?.creatives?.map((creative: Creativeservicev1Creative) => {
          if (creative.id && creativeServiceApi && orderline.orgId) {
            return creativeServiceApi
              ?.advertisingPlatformServiceGetCreative(
                creative.id,
                orderline.orgId,
              )
              .then((res) => {
                return {
                  creative: res,
                }
              })
              .catch((err) =>
                Promise.resolve({
                  err:
                    err?.body?.message === 'creativeNotFound'
                      ? `Creative not found: ${creative.id}`
                      : `Error getting creative: ${creative.id}. ${
                          err?.body?.message || ''
                        }`,
                }),
              )
          }
          return Promise.resolve({
            err: 'This creative does not have an id.',
            creative,
          })
        })) ||
      []

    Promise.all(creativePromises).then((res: CreativeResType[]) => {
      setCreatives(res)
    })
  }, [orderline])
  useEffect(() => {
    if (campaignOrderlines) {
      const proms = campaignOrderlines?.reduce(
        (acc: Promise<CreativeResType>[] | [], val) => {
          const creativePromises =
            (val?.creatives &&
              val?.creatives?.map((creative: Creativeservicev1Creative) => {
                if (creative.id && creativeServiceApi && creative.orgId) {
                  return creativeServiceApi
                    ?.advertisingPlatformServiceGetCreative(
                      creative.id,
                      creative.orgId,
                    )
                    .then((res) => {
                      return {
                        creative: res,
                      }
                    })
                    .catch((err) =>
                      Promise.resolve({
                        err:
                          err?.body?.message === 'creativeNotFound'
                            ? `Creative not found: ${creative.id}`
                            : `Error getting creative: ${creative.id}. ${
                                err?.body?.message || ''
                              }`,
                      }),
                    )
                }
                return Promise.resolve({
                  err: 'This creative does not have an id.',
                  creative,
                })
              })) ||
            []
          return [...acc, ...creativePromises]
        },
        [],
      )
      Promise.all(proms).then((res: CreativeResType[]) => {
        setCreatives(res)
        /* const cleanedRes = res.map(
         *   (singleRes: CreativeResType): CleanedCreativeResType => {
         *     console.log(singleRes)
         *     if (singleRes.err) return { error: singleRes.err }
         *     return { creative: singleRes.creative }
         *   },
         * )
         * setCreatives(cleanedRes) */
      })
    }
  }, [campaignOrderlines])
  const query = currentOrg?.id ? `?org_id=${currentOrg?.id}` : ''

  const emptyPoliticalFields: V1PoliticalFields = {
    audienceDescription: '',
    audienceIdsTargeted: '',
    orgZip: '',
    paidForBy: '',
    submitterAddress: '',
    submitterCity: '',
    submitterName: '',
    submitterState: '',
    submitterZip: '',
  }
  const handleAddOrderLine = async () => {
    let politicalFields: V1PoliticalFields | undefined = undefined
    let political: boolean = !!campaign?.politicalTransparency
    const today = dayjs().format('YYYY-MM-DD')
    const now = dayjs().add(1, 'day').startOf('day').utc(true).toDate()
    const nowPlusSeven = dayjs().add(8, 'days').endOf('day').utc(true).toDate()
    if (!campaignServiceApi || !campaign?.orgId) return
    const firstOrderLineId = campaign.orderLines?.[0]?.id
    if (firstOrderLineId && political) {
      if (!campaign.orgId) return
      // get the first ol
      const existingOrderLine = await campaignServiceApi.advertisingPlatformServiceGetOrderLine(
        firstOrderLineId,
        campaign.orgId,
      )
      // copy political fields
      politicalFields =
        existingOrderLine.politicalFields || emptyPoliticalFields
    }
    campaignServiceApi
      .advertisingPlatformServiceCreateOrderLine(
        campaign.orgId,
        {
          name: `${campaign?.name}_Draft_${today}`,
          startTime: now,
          endTime: nowPlusSeven,
          impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
          political,
          politicalFields,
        },
        campaign?.id,
      )
      .then(() => {
        navigate(`/campaigns/edit/${campaign?.id}/settings${query}`)
      })
  }
  const handleCopyOrderline = async (campaignId: string) => {
    let politicalFields: V1PoliticalFields | null = null
    if (orderline?.id && orderline.orgId) {
      // if campaignId !== orderline.campaignId - add fields from existing ols on that campaign OR clear them if no ols
      if (campaignId !== orderline.campaign?.id) {
        if (!campaignServiceApi || !orderline.orgId) return
        // get existing campaign's order lines
        const existingCampaign = await campaignServiceApi.advertisingPlatformServiceGetCampaign(
          campaignId,
          orderline.orgId,
        )
        // if existing campaign not political, leave them null
        if (!existingCampaign.politicalTransparency) return

        const firstOrderLineId = existingCampaign.orderLines?.[0]?.id
        if (firstOrderLineId) {
          if (!existingCampaign.orgId) return
          // get the first ol
          const existingOrderLine = await campaignServiceApi.advertisingPlatformServiceGetOrderLine(
            firstOrderLineId,
            existingCampaign.orgId,
          )
          // copy political fields
          politicalFields = existingOrderLine.politicalFields
            ? existingOrderLine.politicalFields
            : emptyPoliticalFields
        } else {
          politicalFields = emptyPoliticalFields
        }
      }
      campaignServiceApi
        ?.advertisingPlatformServiceCloneOrderLine(orderline?.id, {
          orgId: orderline.orgId,
          campaignId,
        })
        .then(async (clonedOl) => {
          if (
            !campaignServiceApi ||
            !clonedOl.id ||
            !clonedOl.orgId ||
            campaignId === orderline.campaign?.id // if the same campaign, do not update political fields
          )
            return
          campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
            clonedOl.id,
            clonedOl.orgId,
            {
              politicalFields:
                // see CampaignContext line 792 for explanation on my typescript hax here
                politicalFields === null ? (null as any) : politicalFields,
            },
            'political_fields',
          )
        })
        .then(() => {
          navigate(`/campaigns/edit/${campaignId}/settings${query}`)
        })
    }
  }
  const handleCampaignCreationFromClone = async (
    cName: string,
    eDate: Date,
    sDate: Date,
    political?: boolean,
  ) => {
    const politicalTransparency = {
      isAccuracyAcknowledged: false,
      isExpenditureCommittee: false,
    }
    const base = {
      name: cName,
      startTime: sDate,
      endTime: eDate,
    }
    const { campaignServiceAPI } = ApiFactory(
      localStorage.getItem('eltoro_token'),
    )
    if (cName && eDate && sDate && orderline?.orgId) {
      campaignServiceAPI
        ?.advertisingPlatformServiceCreateCampaign(
          orderline.orgId,
          political ? { ...base, politicalTransparency } : { ...base },
        )
        .then((res) => {
          if (res.id && orderline?.id) {
            campaignServiceAPI
              ?.advertisingPlatformServiceCloneOrderLine(orderline?.id, {
                orgId: orderline.orgId,
                campaignId: res.id,
              })
              .then((clonedOl) => {
                if (!clonedOl?.id || !clonedOl?.orgId) return
                // clear the political fields because this is a new campaign
                campaignServiceAPI?.advertisingPlatformServiceUpdateOrderLine(
                  clonedOl.id,
                  clonedOl.orgId,
                  {
                    // see CampaignContext line 792 for explanation on my typescript hax here
                    politicalFields: null as any,
                  },
                  'political_fields',
                )
                navigate(`/campaigns/edit/${res.id}/settings${query}`)
              })
          }
        })
    }
  }
  const handleLockOrderline = () => {
    if (
      campaignServiceApi &&
      orderline?.id &&
      orderline.orgId &&
      roles &&
      roles.includes('nextgen_admin')
    ) {
      campaignServiceApi
        .advertisingPlatformServiceLockOrderLine(orderline?.id, {
          orgId: orderline.orgId,
        })
        .then(() => {
          setLockModal(false)
          if (handleUpdateRow) handleUpdateRow({ ...orderline, locked: true })
          if (setRefreshOL) setRefreshOL(true)
        })
        .catch((err) => {
          showErrorMessage('Error locking order line', err?.body?.message || '')
        })
    }
  }
  const handleUnlockOrderline = () => {
    if (
      campaignServiceApi &&
      orderline?.id &&
      orderline?.orgId &&
      roles &&
      roles.includes('nextgen_admin')
    ) {
      campaignServiceApi
        .advertisingPlatformServiceUnlockOrderLine(orderline?.id, {
          orgId: orderline.orgId,
        })
        .then(() => {
          setLockModal(false)
          if (handleUpdateRow) handleUpdateRow({ ...orderline, locked: false })
          if (setRefreshOL) setRefreshOL(true)
        })
        .catch((err) => {
          showErrorMessage(
            'Error unlocking order line',
            err?.body?.message || '',
          )
        })
    }
  }

  return (
    <div className="ExpansionHeader relative">
      <CollapseButton
        classNameButton="ExpansionHeader__collapse-button desktop:!w-full justify-end !pb-0"
        className="desktop:!w-auto"
        isCollapsed={isCollapsed}
        handleClick={handleCollapse}
        iconName={collapseIconName}
      />

      <div className="ExpansionHeader__content laptopsmall:flex-wrap laptopsmall:gap-0 laptopsmall:justify-end desktop:items-center absolute top-3 right-9 flex flex-1 justify-between gap-4">
        <div className="OrderLine__name-icon flex flex-1 items-center gap-2">
          <div className="flex flex-1 items-center justify-start gap-1">
            {icon}
            <TextHeader
              className="ExpansionHeader__text pointer-events-none truncate leading-snug"
              type={4}
            >
              {name}
            </TextHeader>
            <div className="OrderLine__icons-no-text gap-5">
              {orderline?.id && (
                <button
                  className={`LockIcon__tooltip Button ${
                    !isAdmin && 'pointer-events-none'
                  }`}
                  aria-label={
                    orderline?.locked ? 'Unlock order line' : 'Lock order line'
                  }
                  data-tooltip="right"
                  onClick={() => {
                    if (isAdmin) {
                      setLockModal(true)
                    }
                  }}
                >
                  <Fa
                    icon={orderline?.locked ? 'lock' : 'lock-open'}
                    className={`text-primary scale-75 ${
                      roles &&
                      !roles.includes('nextgen_admin') &&
                      !isOnHold &&
                      'pointer-events-none'
                    }`}
                    size={1}
                  />
                </button>
              )}
              <PermalinkButton
                url={(() => {
                  if (orderline?.id && orderline?.orgId) {
                    return `/campaigns/dashboard/order-lines/${orderline.id}?org_id=${orderline.orgId}`
                  }
                  if (campaign?.id && campaign?.orgId) {
                    return `/campaigns/dashboard/${campaign.id}?org_id=${campaign.orgId}`
                  }
                  return ''
                })()}
                className="desktop:mr-auto"
              />
            </div>
          </div>
        </div>
        <div className="OrderLine__icons items-center gap-4">
          <div className="OrderLine__icons-no-text">
            {orderline && (
              <OrderLineNotes
                orderLine={orderline}
                rejected={orderline.status === 'ORDERLINE_STATUS_REJECTED'}
              />
            )}
            {orderline && <OrderLineEventHistory orderline={orderline} />}
          </div>
          <div className="OrderLine__icons-with-text flex flex-wrap items-center gap-4">
            {campaign &&
              !orderline &&
              roles &&
              !roles.includes('nextgen_read_only') &&
              !isOnHold && (
                // TODO: add order line
                <>
                  <Button
                    className="AddOrderLineButton"
                    iconLeft={<Fa icon="add" size={1} />}
                    ariaLabel="Add Order Line"
                    dataTooltip="bottom"
                    onClick={handleAddOrderLine}
                  >
                    Add Order Line
                  </Button>
                </>
              )}
            {roles &&
              !roles.includes('nextgen_read_only') &&
              !isOnHold &&
              type === 'orderLine' && (
                <Button
                  className="CopyButton"
                  iconLeft={<Fa icon="copy" size={1} />}
                  ariaLabel="Copy order line"
                  dataTooltip="left"
                  onClick={() => setCloneModal(true)}
                >
                  {`Copy ${orderline ? 'order line' : 'campaign'}`}
                </Button>
              )}

            {type === 'orderLine' && (
              <>
                <Button
                  disabled={!hasDeployed || !pastStartDate}
                  className="PerformanceButton"
                  iconLeft={<Fa icon="chart-line" size={1} />}
                  onClick={() => setShowReport(true)}
                  ariaLabel={`${
                    !hasDeployed || !pastStartDate
                      ? 'Performance Report unavailable'
                      : 'Click to see Performance Button'
                  }`}
                  dataTooltip="left"
                >
                  Performance Report
                </Button>
              </>
            )}
            <Button
              iconLeft={<Fa icon="question" size={1} />}
              onClick={() => setSupportModal(true)}
              ariaLabel="Support Request"
              dataTooltip="left"
            >
              Support Request
            </Button>
          </div>
        </div>
      </div>
      {campaign && orderline && showReport && (
        <ReportModal
          creatives={creatives}
          orderline={orderline}
          closeModal={() => setShowReport(false)}
        />
      )}
      {lockModal && (
        <Modal
          offClick={() => setLockModal(false)}
          className={classNames({ 'max-w-[32rem]': lockModal })}
        >
          <div className="LockOrderLine__modal flex flex-col gap-1">
            <TextHeader className="flex flex-col" type={3}>
              <span>
                {orderline?.locked ? 'Unlock' : 'Lock'} this order line?
              </span>
              <span className="text-l font-normal normal-case leading-none">
                {name}
              </span>
            </TextHeader>

            <div className="ButtonGroup mb-2 flex justify-between pt-8">
              <Button
                onClick={() => {
                  setLockModal(false)
                }}
              >
                Cancel
              </Button>
              {orderline?.locked ? (
                <Button onClick={handleUnlockOrderline}>Unlock!</Button>
              ) : (
                <Button onClick={handleLockOrderline}>Lock!</Button>
              )}
            </div>
          </div>
        </Modal>
      )}
      {cloneModal && (
        <Modal
          offClick={() => {
            setCloneModal(false)
            setShowCampaignCreator(false)
            setShowSelect(false)
          }}
          className={classNames({
            'min-h-fit min-w-[64rem]': showCampaignCreator,
            'min-w-[24rem]': showSelect,
          })}
        >
          <div>
            <div
              className={`flex flex-row-reverse justify-between ${
                !showCampaignCreator && 'pb-4'
              }`}
            >
              <Button
                className="CopyOrderlineModal_Close_Button"
                kind="text"
                onClick={() => setCloneModal(false)}
                iconOnly={<Fa icon="times" size={1} />}
              />
              <TextHeader type={3} className="CopyOrderlineModal_header">
                Copy Order Line
              </TextHeader>
            </div>

            {!showSelect && !showCampaignCreator && (
              <div className="flex">
                <div className="p-4">
                  <Button
                    onClick={() => {
                      if (
                        orderline &&
                        orderline.campaign &&
                        orderline.campaign.id
                      ) {
                        handleCopyOrderline(orderline?.campaign?.id)
                      }
                    }}
                  >
                    Same Campaign?
                  </Button>
                </div>
                <div className="p-4">
                  <Button
                    onClick={() => {
                      setShowCampaignCreator(true)
                    }}
                  >
                    New Campaign?
                  </Button>
                </div>
                <div className="p-4">
                  <Button
                    onClick={() => {
                      setShowSelect(true)
                    }}
                  >
                    Existing Campaign?
                  </Button>
                </div>
              </div>
            )}
            {showSelect && (
              <div className="m-auto max-w-fit pt-4">
                <Text>Select an Existing Campaign</Text>
                <CampaignSelect
                  currentCampaignId={orderline?.campaign?.id}
                  onCampaignSelect={(campId) => {
                    handleCopyOrderline(campId)
                  }}
                />
                <div className="pt-2">
                  <Button
                    onClick={() => {
                      setShowSelect(false)
                    }}
                  >
                    Cancel?
                  </Button>
                </div>
              </div>
            )}
            {showCampaignCreator && (
              <div className="m-auto pr-2 pl-2 pt-2">
                <Text>
                  Create a new Campaign, Your order line will be able to make
                  modifications to the order line after creation
                </Text>
                <CampaignDetailRow className="items-start">
                  <CampaignLabelWrap>
                    <IconWrap>
                      <Fa
                        icon="fa-ad"
                        type="duotone"
                        size={5}
                        className="text-primary-400"
                      />
                    </IconWrap>
                    <CampaignLabel>
                      <TextHeader className="text-m normal-case" type={5}>
                        Campaign Name
                      </TextHeader>
                    </CampaignLabel>
                  </CampaignLabelWrap>
                  <CampaignInputWrap className="CampaignSplash_Name-input flex-col gap-1">
                    <TextInput
                      className="CampaignSplash_name-input-field text-tint-gray-800 text-l bg-tint-gray-50 py-2"
                      value={campName || ''}
                      onChange={(e) => setCampName(e.target.value)}
                      placeholder="Title of your advertising campaign"
                      size="m"
                      maxLength={256}
                      valid={
                        !isDisabled && !lengthLimit && campName?.length > 1
                      }
                      hideValidIcon
                    />
                    {lengthLimit && (
                      <div className="LengthLimit__error-message text-m text-warning-500 animate-slidedown pb-3 font-semibold">
                        Campaign name cannot exceed 255 characters
                      </div>
                    )}
                  </CampaignInputWrap>
                </CampaignDetailRow>
                <CampaignDetailRow className="items-center">
                  <CampaignLabelWrap>
                    <IconWrap>
                      <ETIcon icon="advocacy" />
                    </IconWrap>
                    <CampaignLabel>
                      <TextHeader className="text-m normal-case" type={5}>
                        Political
                      </TextHeader>
                      <small className="leading-none">
                        Campaigns that support political candidates,
                        initiatives, or advocacy causes
                      </small>
                    </CampaignLabel>
                  </CampaignLabelWrap>
                  <CampaignInputWrap className="CampaignSplash_Political-checkbox gap-4">
                    <FormItem htmlFor="political">
                      <RadioButtonGroup
                        className="PoliticalCampaign-radio-group"
                        layout="horizontal"
                        name="Political campaign"
                        valueSelected={political === true ? 'yes' : 'no'}
                        onChange={() => setPolitical(!political)}
                      >
                        <RadioButton
                          id="yes"
                          value="yes"
                          checked={political}
                          label="Yes"
                        />
                        <RadioButton
                          id="no"
                          value="no"
                          checked={!political}
                          label="No"
                        />
                      </RadioButtonGroup>
                    </FormItem>
                  </CampaignInputWrap>
                </CampaignDetailRow>
                <div className="flex justify-between pt-8">
                  <Button
                    onClick={() => {
                      setShowCampaignCreator(false)
                    }}
                  >
                    Cancel?
                  </Button>
                  <Button
                    onClick={() => {
                      handleCampaignCreationFromClone(
                        campName,
                        calcEndDate,
                        calcStartDate,
                        political,
                      )
                    }}
                  >
                    Create?
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
      {supportModal && (orderline || campaign) && (
        <SupportRequestModal
          handleClose={() => setSupportModal(false)}
          orderLine={orderline}
          campaign={campaign}
        />
      )}
    </div>
  )
}
