/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Deployment } from '../models/Deployservicev3Deployment';
import { Deployservicev3Status } from '../models/Deployservicev3Status';
import { V3SegmentType } from '../models/V3SegmentType';
import { HttpFile } from '../http/http';

/**
* The Segment object created by the deploy service.
*/
export class V3Segment {
    /**
    * The ID of the segment.
    */
    'id'?: string;
    /**
    * The time the object was created.
    */
    'createTime'?: Date;
    /**
    * The time the object was updated.
    */
    'updateTime'?: Date;
    /**
    * The string created by the deploy service that is 'SEG' plus the code below as a string.
    */
    'codeString'?: string;
    /**
    * The code of the segment.
    */
    'code'?: number;
    /**
    * The reference ID for the segment.
    */
    'refId'?: string;
    'status'?: Deployservicev3Status;
    'type'?: V3SegmentType;
    /**
    * The location of the segment file.
    */
    'location'?: string;
    /**
    * The name of the segment.
    */
    'name'?: string;
    /**
    * The size of the segment.
    */
    'size'?: number;
    /**
    * The number of targets in a segment.
    */
    'count'?: number;
    /**
    * The ID of the Advertiser this Segment is related to.
    */
    'advertiserId'?: string;
    /**
    * The array of deployments created for this segment.
    */
    'deployments'?: Array<Deployservicev3Deployment>;
    /**
    * The ID of the Advertiser this Segment is related to.
    */
    'exclude'?: boolean;
    /**
    * The time the object was deleted.
    */
    'deleteTime'?: Date;
    /**
    * The time at which the segment needs to be reviewed for inactivity
    */
    'expiryTime'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "codeString",
            "baseName": "code_string",
            "type": "string",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Deployservicev3Status",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V3SegmentType",
            "format": ""
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "count",
            "baseName": "count",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "advertiserId",
            "baseName": "advertiser_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "deployments",
            "baseName": "deployments",
            "type": "Array<Deployservicev3Deployment>",
            "format": ""
        },
        {
            "name": "exclude",
            "baseName": "exclude",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "expiryTime",
            "baseName": "expiry_time",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return V3Segment.attributeTypeMap;
    }

    public constructor() {
    }
}

