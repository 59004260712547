/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Billingservicev1Invoice } from '../models/Billingservicev1Invoice';
import { V1CreditCard } from '../models/V1CreditCard';
import { HttpFile } from '../http/http';

/**
* Billing Org Information
*/
export class Billingservicev1Org {
    'id'?: string;
    /**
    * Balance of the org
    */
    'balance'?: number;
    'isBillingReady'?: boolean;
    'creditCards'?: Array<V1CreditCard>;
    /**
    * List of invoices
    */
    'invoices'?: Array<Billingservicev1Invoice>;
    /**
    * Will be true if an org has at least one valid, unexpired credit card. If no valid credit cards are associated with the org, will be false.
    */
    'areCreditCardsReady'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "balance",
            "baseName": "balance",
            "type": "number",
            "format": "double"
        },
        {
            "name": "isBillingReady",
            "baseName": "is_billing_ready",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "creditCards",
            "baseName": "credit_cards",
            "type": "Array<V1CreditCard>",
            "format": ""
        },
        {
            "name": "invoices",
            "baseName": "invoices",
            "type": "Array<Billingservicev1Invoice>",
            "format": ""
        },
        {
            "name": "areCreditCardsReady",
            "baseName": "are_credit_cards_ready",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Billingservicev1Org.attributeTypeMap;
    }

    public constructor() {
    }
}

