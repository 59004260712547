import React, { useState, useContext, useEffect, useCallback } from 'react'
import {
  useDebounce,
  useTokenPagination,
  TokenPaginationType,
} from '@eltoro-ui/hooks'
import {
  Creativeservicev1Creative,
  V1ListCreativeFoldersResponse,
  Targetjobservicev1Audience,
  V1ListAudienceFoldersResponse,
  V1ListAudiencesResponse,
  Creativeservicev1ListCreativesResponse,
  Creativeservicev1Type,
  V1AudienceType,
  V1ProductType,
  Creativeservicev1AdType,
  V1AudienceStatus,
  V1Result,
  V1AudienceSubType,
} from 'next-gen-sdk'
import {
  FilterValueType,
  showErrorMessage,
  showSuccessMessage,
} from '@eltoro-ui/components'
import { useAppContext } from 'Contexts'
import {
  creativeTypesEnum,
  audienceTypesEnum,
  audienceProductTypeEnum,
  creativeAdTypesEnum,
  creativeStatusesEnum,
  audienceStatusEnum,
  audienceSubTypeEnum,
} from 'Enums'
import { checkIfCreative, getEnumKey, getOrgFilter } from 'Helpers'
import { useSSEListener, useLibraryFolderList } from 'Hooks'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export type FolderRowType = {
  name?: string
  type?: 'Folder'
  count?: number
}
export type ItemType = Targetjobservicev1Audience | Creativeservicev1Creative

type SortType = { field: string; direction: 'asc' | 'desc' }
type FilterType = {
  [field: string]: Array<string | number>
}

type LibraryContextType = {
  currentData: Array<ItemType>
  currentFolderPath: string[]
  setCurrentFolderPath: (path: string[]) => void
  pagination: TokenPaginationType<ItemType>
  folderPagination: TokenPaginationType<FolderRowType>
  handleRowClick: (clicked: ItemType) => void

  // Selected item
  selectedItem?: ItemType
  handleSetSelectedItem: (item: ItemType) => void
  handleClearSelectedItem: () => void

  // Editing folders
  moveToNewFolder: (itemId: string, folder: string, orgId: string) => void
  removeFromFolder: (item: ItemType, folder: string, orgId: string) => void
  deleteFolder: (item: ItemType, folder: string, orgId: string) => void
  renameFolder: (item: ItemType, newName: string, orgId: string) => void
  folderList: string[]

  // Sorting
  sort?: SortType
  setSort: React.Dispatch<React.SetStateAction<SortType | undefined>>
  folderSort?: SortType
  setFolderSort: React.Dispatch<React.SetStateAction<SortType | undefined>>
  handleSort: (
    columnIndex: number,
    sort?: 'asc' | 'desc',
    path?: string | string[],
  ) => void

  // Filtering
  childOrgsOnly: boolean
  setChildOrgsOnly: React.Dispatch<React.SetStateAction<boolean>>
  showArchived: boolean
  setShowArchived: React.Dispatch<React.SetStateAction<boolean>>
  tableFilter?: FilterType
  setTableFilter: React.Dispatch<React.SetStateAction<FilterType | undefined>>
  handleTableFilter: (
    columnIndex: number,
    path: string | string[],
    filterOn: FilterValueType,
  ) => void
  setChildOrgsFilter: (x: string[]) => void
  childOrgFilter: string[]
  handleGlobalFilter: (keys: string[], filterOn: string[]) => void
}

export const libraryContextBaseState: LibraryContextType = {
  currentData: [],
  currentFolderPath: [''],
  setCurrentFolderPath: () => {},
  pagination: {
    page: 0,
    setPage: () => {},
    totalPages: 1,
    pageSize: 10,
    setPageSize: () => {},
    currentPageData: [],
    loading: false,
    totalItems: 1,
    refetch: () => {},
    updateRow: () => {},
    findCachedRow: () => undefined,
  },
  folderPagination: {
    page: 0,
    setPage: () => {},
    totalPages: 1,
    pageSize: 10,
    setPageSize: () => {},
    currentPageData: [],
    loading: false,
    totalItems: 1,
    refetch: () => {},
    updateRow: () => {},
    findCachedRow: () => undefined,
  },
  selectedItem: undefined,
  handleSetSelectedItem: () => {},
  handleClearSelectedItem: () => {},
  handleRowClick: () => {},
  moveToNewFolder: () => {},
  renameFolder: () => {},
  removeFromFolder: () => {},
  deleteFolder: () => {},
  folderList: [],
  setSort: () => {},
  setFolderSort: () => {},
  setTableFilter: () => {},
  handleSort: () => {},
  handleTableFilter: () => {},
  childOrgsOnly: false,
  setChildOrgsOnly: () => {},
  showArchived: false,
  setShowArchived: () => {},
  setChildOrgsFilter: () => {},
  childOrgFilter: [],
  handleGlobalFilter: () => {},
}

export const LibraryContext = React.createContext<LibraryContextType>(
  libraryContextBaseState,
)

export const useLibraryContext = () => {
  const ctx = useContext(LibraryContext)
  if (!ctx)
    throw new Error(
      'useLibraryContext must be used within the LibraryContextProvider',
    )
  return ctx
}

type CreativeLibraryContext = {
  type: 'creatives'
  filterByType?: Creativeservicev1Type[]
  filterByProductType?: never
  filterByAdType?: Creativeservicev1AdType[]
  children?: React.ReactNode
}
type TargetLibraryContext = {
  type: 'audiences'
  filterByType?: V1AudienceType[]
  filterByProductType?: V1ProductType[]
  filterByAdType?: never
  children?: React.ReactNode
}

export const LibraryContextProvider: React.FC<
  CreativeLibraryContext | TargetLibraryContext
> = ({ children, type, filterByType, filterByProductType, filterByAdType }) => {
  const [currentFolderPath, setCurrentFolderPath] = useState<string[]>([''])
  const [selectedItem, setSelectedItem] = useState<ItemType>()
  // Sorting
  const [sort, setSort] = useState<SortType>()
  const [folderSort, setFolderSort] = useState<SortType>()
  // Filtering
  const [childOrgsOnly, setChildOrgsOnly] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [tableFilter, setTableFilter] = useState<FilterType>()
  const [childOrgFilter, setChildOrgsFilter] = useState<string[]>([])
  const debouncedTableFilter = useDebounce(tableFilter, 200)
  const [globalFilter, setGlobalFilter] = useState<FilterType>()
  const debouncedGlobalFilter = useDebounce(globalFilter, 200)
  const filterString =
    debouncedTableFilter &&
    Object.entries(debouncedTableFilter)
      .reduce((acc: string[], [key, value]) => {
        if (!value.length) return acc
        const string = value
          .map(
            (val) =>
              `${key} = ${
                typeof val === 'number' || key === 'status' ? val : `"*${val}*"`
              }`,
          )
          .join(' OR ')
        return [...acc, string]
      }, [])
      .join(' AND ')

  const typeFilter =
    filterByType &&
    `AND type = ${filterByType
      .map(
        (filterType: string) =>
          `${
            type === 'creatives'
              ? creativeTypesEnum[filterType]
              : audienceTypesEnum[filterType]
          }`,
      )
      .join(' OR type = ')}`

  const productTypeFilter =
    filterByProductType &&
    type === 'audiences' &&
    `AND product_type = ${filterByProductType
      .map((filterType: string) => `${audienceProductTypeEnum[filterType]}`)
      .join(' OR product_type = ')}`

  const adTypeFilter =
    filterByAdType &&
    type === 'creatives' &&
    `AND ad_type = ${filterByAdType
      .map((filterType: string) => `${creativeAdTypesEnum[filterType]}`)
      .join(' OR ad_type = ')}`

  const globalFilterString =
    debouncedGlobalFilter &&
    Object.entries(debouncedGlobalFilter)
      .reduce((acc: string[], [key, value]) => {
        if (!value.length) return acc
        const string = value
          .map(
            (val) =>
              `${key} = ${
                typeof val === 'number' || key === 'status' ? val : `"*${val}*"`
              }`,
          )
          .join(' OR ')
        return [...acc, string]
      }, [])
      .join(' OR ')

  const {
    currentOrg,
    audienceServiceApi,
    creativeServiceApi,
    setRebuild,
    rebuild,
    errorRebuildCount,
  } = useAppContext()

  const { creativeId, audienceId } = useParams<{
    creativeId?: string
    audienceId?: string
  }>()
  const orgId = new URLSearchParams(window.location.search).get('org_id')
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isStandAlone =
    type === 'audiences'
      ? pathname.includes('/audiences/audience-library')
      : pathname.includes('/campaigns/creative-library')

  const baseUrl = `/${
    type === 'creatives' ? 'campaigns' : 'audiences'
  }/${type.slice(0, -1)}-library`

  /* This useCallback: handles the reinitilization of handleFetchItems
  after any of the depndancies change. The big ones, creativeService/targetServiceApi 
  Prior to thiis addition. When services rebuilt the fetch here would be called using the previous
  version of the service with an expired token causing cascading 401s */
  const handleFetchItems = useCallback(
    async ({
      pageSize,
      nextPageToken,
    }: {
      pageSize: number
      nextPageToken?: string
    }) => {
      const empty = {
        data: [],
        totalItems: 0,
      }

      if (!currentOrg?.id) return empty
      const archiveFilter = () => {
        // if filterString INCLUDES status OR if we need to show the archived, then ignore this filter
        if (
          (filterString?.includes('status') &&
            !filterString?.includes('!= 401') &&
            !filterString?.includes('!= 100')) ||
          showArchived
        )
          return ''
        if (type === 'creatives') {
          return 'AND status != 401'
        } else {
          return 'AND status != 100'
        }
      }
      const orderBy =
        sort && `${sort.field}${sort.direction === 'desc' ? ' desc' : ''}`
      const folderName = currentFolderPath[currentFolderPath.length - 1]
      const filter = `${
        childOrgFilter && childOrgFilter.length > 0
          ? getOrgFilter(false, currentOrg, childOrgFilter)
          : getOrgFilter(childOrgsOnly, currentOrg)
      }${folderName ? ` AND folder = "${folderName}"` : ''} ${
        typeFilter || ''
      } ${productTypeFilter || ''} ${adTypeFilter || ''} ${
        globalFilterString ? `AND ${globalFilterString}` : ''
      } ${filterString ? `AND ${filterString}` : ''}${
        // removes VR sub jobs from the listing
        type === 'audiences'
          ? ' AND sub_type != 7 AND sub_type != 1 AND sub_type != 3 AND sub_type != 5'
          : ''
      } ${archiveFilter()}`
      try {
        const res =
          type === 'creatives'
            ? await creativeServiceApi
                ?.advertisingPlatformServiceListCreatives(
                  pageSize,
                  nextPageToken,
                  orderBy,
                  filter,
                )
                .catch((err) => {
                  const {
                    body: { code, message },
                  } = err
                  if (code === 401 && errorRebuildCount.current < 3) {
                    const newCount = errorRebuildCount.current + 1
                    errorRebuildCount.current = newCount
                    setRebuild(true)
                  }
                  if (code !== 401 || errorRebuildCount.current === 3)
                    showErrorMessage(
                      `Error getting ${type}`,
                      `${message || ''}`,
                    )
                  return undefined
                })
            : await audienceServiceApi
                ?.advertisingPlatformServiceListAudiences(
                  pageSize,
                  nextPageToken,
                  orderBy,
                  filter,
                )
                .catch((err) => {
                  const {
                    body: { code, message },
                  } = err
                  if (code === 401 && errorRebuildCount.current < 3) {
                    const newCount = errorRebuildCount.current + 1
                    errorRebuildCount.current = newCount
                    setRebuild(true)
                  }
                  if (code !== 401 || errorRebuildCount.current === 3)
                    showErrorMessage(
                      `Error getting ${type}`,
                      `${message || ''}`,
                    )
                  return undefined
                })
        if (res && !rebuild) {
          errorRebuildCount.current = 0
          const items =
            type === 'creatives'
              ? (res as Creativeservicev1ListCreativesResponse).creatives
              : (res as V1ListAudiencesResponse).audiences
          return {
            data: items || [],
            totalItems: res.totalSize || 0,
            nextPageToken: res.nextPageToken,
          }
        }
      } catch {
        showErrorMessage(`Error fetching your ${type}`, '')
      }

      return empty
    },
    [
      currentOrg,
      sort,
      currentFolderPath,
      childOrgsOnly,
      typeFilter,
      productTypeFilter,
      adTypeFilter,
      filterString,
      type,
      showArchived,
      creativeServiceApi,
      audienceServiceApi,
      rebuild,
      errorRebuildCount,
      setRebuild,
      childOrgFilter,
      globalFilterString,
    ],
  )

  const pagination = useTokenPagination<ItemType>(
    {
      pageSize: 10,
      fetchData: handleFetchItems,
    },
    [
      currentFolderPath,
      sort,
      filterString,
      currentOrg,
      childOrgsOnly,
      showArchived,
      childOrgFilter,
      globalFilterString,
    ],
  )
  /* This useCallback: handles the reinitilization of handleFetchFolders
  after any of the depndancies change. The big ones, creativeService/targetServiceApi 
  Prior to thiis addition. When services rebuilt the fetch here would be called using the previous
  version of the service with an expired token causing cascading 401s */
  const folderOrderBy =
    folderSort &&
    `${folderSort.field}${folderSort.direction === 'desc' ? ' desc' : ''}`
  const folderFilter = getOrgFilter(childOrgsOnly, currentOrg)
  const handleFetchFolders = useCallback(
    async ({
      pageSize,
      nextPageToken,
    }: {
      pageSize: number
      nextPageToken?: string
    }) => {
      const empty = {
        data: [],
        totalItems: 0,
      }
      if (!currentOrg?.id || !audienceServiceApi) return empty
      try {
        if (type === 'audiences' && audienceServiceApi && !rebuild) {
          const res:
            | V1ListAudienceFoldersResponse
            | undefined = await audienceServiceApi
            ?.advertisingPlatformServiceListAudienceFolders(
              pageSize, // pageSize
              nextPageToken, // pageToken
              folderOrderBy, // orderBy
              folderFilter, // filter
            )
            .catch(() => undefined)
          if (!res) return empty
          // convert to FolderRowType
          const folderRows =
            res.folders && currentFolderPath.length === 1 // if this is the home folder
              ? (res.folders
                  .filter((folder) => folder.name !== '')
                  .map(({ name, count }) => {
                    return {
                      name,
                      count: count || 0,
                      type: 'Folder',
                    }
                  }) as FolderRowType[])
              : []

          return {
            data: folderRows,
            totalItems: res.totalSize || 0,
            nextPageToken: res.nextPageToken,
          }
        }

        if (type === 'creatives' && creativeServiceApi && !rebuild) {
          const res: V1ListCreativeFoldersResponse = await creativeServiceApi
            .advertisingPlatformServiceListCreativeFolders(
              pageSize, // pageSize
              nextPageToken, // pageToken
              folderOrderBy, // orderBy
              folderFilter, // filter
            )
            .catch(() => {
              return {
                totalSize: 0,
              }
            })
          // convert to FolderRowType
          const folderRows =
            res.folders && currentFolderPath.length === 1 // if this is the home folder
              ? (res.folders
                  .filter((folder) => folder.name !== '')
                  .map(({ name, count }) => {
                    return {
                      name,
                      count: count || 0,
                      type: 'Folder',
                    }
                  }) as FolderRowType[])
              : []

          return {
            data: folderRows,
            totalItems: res.totalSize || 0,
            nextPageToken: res.nextPageToken,
          }
        }
      } catch {}
      return empty
    },
    [
      currentOrg?.id,
      audienceServiceApi,
      type,
      rebuild,
      creativeServiceApi,
      folderOrderBy,
      folderFilter,
      currentFolderPath.length,
    ],
  )

  const folderPagination = useTokenPagination<FolderRowType>(
    {
      pageSize: 5,
      fetchData: handleFetchFolders,
    },
    [folderSort, currentOrg, childOrgsOnly, showArchived],
  )

  const [jobFinishedMsg, clearJobFinishedMsg] = useSSEListener('JobFinished')
  const [jobUpdatedMsg, clearJobUpdatedMsg] = useSSEListener('JobUpdated')
  const [creativeUpdatedMsg, clearCreativeUpdatedMsg] = useSSEListener(
    'CreativeUpdated',
  )

  useEffect(() => {
    if (!creativeUpdatedMsg || type !== 'creatives') return
    const { message } = creativeUpdatedMsg
    const itemIndex = pagination.currentPageData.findIndex(
      (item) => item.id === message.creative.id,
    )

    if (itemIndex >= 0 || message.creative.id === selectedItem?.id) {
      const update = {
        ...(message.update_mask?.paths?.includes('name') && {
          name: message.creative.name,
        }),
        ...(message.update_mask?.paths?.includes('folder') && {
          folder: message.creative.folder,
        }),
        ...(message.update_mask?.paths?.includes('status') && {
          status: creativeStatusesEnum[message.creative.status],
        }),
        ...(message.creative.ad_tag && {
          adTag: message.creative.ad_tag,
        }),
      }

      pagination.updateRow((item) => item?.id === message?.creative?.id, update)

      if (message.creative.id === selectedItem?.id) {
        setSelectedItem((prev) => {
          if (prev?.id === message.creative?.id) return { ...prev, ...update }
          return prev
        })
      }

      clearCreativeUpdatedMsg()
    }
  }, [creativeUpdatedMsg, clearCreativeUpdatedMsg, type])

  useEffect(() => {
    if (!jobUpdatedMsg || type !== 'audiences') return
    const { message } = jobUpdatedMsg
    const itemIndex = pagination.currentPageData.findIndex(
      (item) => item.id === message.audience.id,
    )

    if (message?.update_mask?.paths?.includes('match_cap')) {
      if (!message?.audience?.match_cap) {
        showSuccessMessage(
          `Audience Updated ${
            message.audience?.name ? `: ${message.audience?.name}` : ''
          }`,
          'Match cap has been removed and is now re-quoting.',
        )
      } else {
        showSuccessMessage(
          `Audience Updated ${
            message.audience?.name ? `: ${message.audience?.name}` : ''
          }`,
          `The audience has been match capped to ${message?.audience?.match_cap} and is now re-quoting.`,
        )
      }
      // if this is a VR sub job, update the parent
      if (message.audience?.audience_id) {
        const parentAudience = pagination.findCachedRow(
          (item) => item?.id === message.audience?.audience_id,
        )?.existingItem
        if (parentAudience && !checkIfCreative(parentAudience)) {
          const previousAudience =
            parentAudience?.audiences?.find(
              (a) => a.id === message.audience.id,
            ) || {}
          const updatedSubJobs = [
            ...(parentAudience?.audiences?.filter(
              (a) => a.id !== message.audience.id,
            ) || []),
            {
              ...previousAudience,
              matchCap: message.audience.match_cap,
            },
          ]
          pagination.updateRow((item) => item?.id === parentAudience?.id, {
            audiences: updatedSubJobs,
          })
        }
        // if parent audience is in preview panel
        if (message.audience.audience_id === selectedItem?.id) {
          setSelectedItem((prev) => {
            if (
              prev &&
              prev?.id === message.audience?.audience_id &&
              !checkIfCreative(prev)
            ) {
              const previousAudience =
                prev?.audiences?.find((a) => a.id === message.audience.id) || {}
              const updatedSubJobs = [
                ...(prev?.audiences?.filter(
                  (a) => a.id !== message.audience.id,
                ) || []),
                {
                  ...previousAudience,
                  matchCap: message.audience.match_cap,
                },
              ]
              return { ...prev, audiences: updatedSubJobs }
            }
          })
        }
      }
    }

    if (itemIndex >= 0 || message.audience.id === selectedItem?.id) {
      const update = {
        ...(message.update_mask?.paths?.includes('name') && {
          name: message.audience.name,
        }),
        ...(message.update_mask?.paths?.includes('folder') && {
          folder: message.audience.folder,
        }),
        ...(message.update_mask?.paths?.includes('status') && {
          status: getEnumKey(audienceStatusEnum, message.audience.status),
        }),
        ...(message.update_mask?.paths?.includes('hidden') && {
          hidden: message.audience.hidden,
        }),
        ...(message.update_mask?.paths?.includes('match_cap') && {
          matchCap: message.audience.match_cap,
          status: 'AUDIENCE_STATUS_RUNNING',
        }),
        ...(message.update_mask?.paths?.includes('locked') && {
          locked: message.audience.locked,
        }),
      }

      // update the table cache
      pagination.updateRow((item) => item?.id === message?.audience?.id, update)

      // If this is the current selected item
      if (message.audience?.id === selectedItem?.id) {
        setSelectedItem((prev) => {
          if (prev?.id === message.audience?.id) return { ...prev, ...update }
          return prev
        })
      }

      clearJobUpdatedMsg()
    }
  }, [jobUpdatedMsg, clearJobUpdatedMsg, type])

  const convertResults = (SSEResult: { [key: string]: any }) =>
    Object.fromEntries(
      Object.entries(SSEResult).map(([key, val]) => [
        key.replace(/_([a-z])/g, (_, m) => m.toUpperCase()),
        val,
      ]),
    )

  useEffect(() => {
    if (!jobFinishedMsg || type !== 'audiences') return
    const { message } = jobFinishedMsg

    // if this is a VR sub job
    if (message.audience.audience_id) {
      const parentAudience = pagination.findCachedRow(
        (item) => item?.id === message.audience?.audience_id,
      )?.existingItem
      if (parentAudience && !checkIfCreative(parentAudience)) {
        const previousAudience =
          parentAudience?.audiences?.find(
            (a) => a.id === message.audience.id,
          ) || {}
        const updatedSubJobs = [
          ...(parentAudience?.audiences?.filter(
            (a) => a.id !== message.audience.id,
          ) || []),
          {
            ...previousAudience,
            result: convertResults(message.audience.result),
            matchCap: message.audience.match_cap,
            initialMatches: message.audience.initial_matches,
          },
        ]
        pagination.updateRow((item) => item?.id === parentAudience?.id, {
          audiences: updatedSubJobs,
        })
        if (message.audience.audience_id === selectedItem?.id) {
          setSelectedItem((prev) => {
            if (
              prev &&
              prev?.id === message.audience?.audience_id &&
              !checkIfCreative(prev)
            ) {
              const previousAudience =
                prev?.audiences?.find((a) => a.id === message.audience.id) || {}
              const updatedSubJobs = [
                ...(prev?.audiences?.filter(
                  (a) => a.id !== message.audience.id,
                ) || []),
                {
                  ...previousAudience,
                  result: convertResults(message.audience.result),
                  matchCap: message.audience.match_cap,
                  initialMatches: message.audience.initial_matches,
                },
              ]
              return { ...prev, audiences: updatedSubJobs }
            }
          })
        }
      }
    }

    // if VR finished
    const update = {
      ...(!checkIfCreative(message.audience) && {
        result: convertResults(message.audience.result),
        initialMatches: message.audience.initial_matches,
        ...(message.audience.type === 37 && {
          audiences: message.audience?.audiences.map(
            (aud: {
              status: number
              type: number
              product_type: number
              sub_type: number
              initial_matches: number
              org_id: string
              result: V1Result
            }) => {
              return {
                ...aud,
                orgId: aud.org_id,
                initialMatches: aud.initial_matches,
                result: convertResults(aud.result),
                status: getEnumKey(
                  audienceStatusEnum,
                  aud.status,
                ) as V1AudienceStatus,
                type: getEnumKey(audienceTypesEnum, aud.type) as V1AudienceType,
                productType: getEnumKey(
                  audienceProductTypeEnum,
                  aud.product_type,
                ) as V1ProductType,
                subType: getEnumKey(
                  audienceSubTypeEnum,
                  aud.sub_type,
                ) as V1AudienceSubType,
              }
            },
          ),
        }),
        status: getEnumKey(
          audienceStatusEnum,
          message.audience.status,
        ) as V1AudienceStatus,
      }),
    }
    pagination.updateRow((item) => item?.id === message?.audience?.id, update)

    if (
      message.audience.id === selectedItem?.id &&
      !checkIfCreative(message.audience)
    ) {
      setSelectedItem((prev) => {
        if (prev?.id === message.audience?.id)
          return { ...prev, ...update } as Targetjobservicev1Audience
        return prev
      })
    }
    clearJobFinishedMsg()
  }, [jobFinishedMsg, clearJobFinishedMsg, type])

  // Reset folder path and close selected item when org changes
  useEffect(() => {
    if (currentOrg?.id) {
      setCurrentFolderPath([''])
      setSelectedItem(undefined)
    }
  }, [currentOrg, childOrgsOnly, showArchived])

  // Fetch the folder list (used in preview panel for moving to different folders)
  const [folderList, addToFolderList] = useLibraryFolderList({
    type,
    selectedItem,
  })

  useEffect(() => {
    if (
      (audienceId || creativeId) &&
      !pagination.loading &&
      pagination.currentPageData.length > 0
    ) {
      // get asset and set as selected

      if (orgId) {
        const query = `?org_id=${orgId}`
        if (
          audienceId &&
          type === 'audiences' &&
          selectedItem?.id !== audienceId
        )
          audienceServiceApi
            ?.advertisingPlatformServiceGetAudience(audienceId, orgId)
            .then(setSelectedItem)
            .catch((err) => {
              if (err?.body?.message === 'audienceNotFound') {
                showErrorMessage(
                  'Audience Not Found',
                  `The audience was not found. Audience ID: ${audienceId}`,
                )
                window.history.replaceState(
                  '',
                  '',
                  `${window.location.origin}/audiences/audience-library${query}`,
                )
              }
            })

        if (
          creativeId &&
          type === 'creatives' &&
          selectedItem?.id !== creativeId
        )
          creativeServiceApi
            ?.advertisingPlatformServiceGetCreative(creativeId, orgId)
            .then(setSelectedItem)
            .catch((err) => {
              if (err?.body?.message === 'creativeNotFound') {
                showErrorMessage(
                  'Creative Not Found',
                  `The creative was not found. Creative ID: ${creativeId}`,
                )
                window.history.replaceState(
                  '',
                  '',
                  `${window.location.origin}/campaigns/creative-library${query}`,
                )
              }
            })
      }
    }
  }, [
    audienceId,
    audienceServiceApi,
    creativeId,
    creativeServiceApi,
    orgId,
    pagination.currentPageData.length,
    pagination.loading,
    selectedItem,
    setSelectedItem,
    type,
  ])

  const resetAfterFolderEdit = () => {
    // resets when in folder and removing last item
    if (
      currentFolderPath.length !== 1 &&
      pagination.currentPageData.length === 1
    ) {
      navigate(
        `${
          type === 'creatives'
            ? `/campaigns/creative-library?org_id=${currentOrg?.id}`
            : `/audiences/audience-library?org_id=${currentOrg?.id}`
        }`,
        { replace: true },
      )
      setSelectedItem(undefined)
      setCurrentFolderPath([''])
      setTimeout(() => {
        pagination.refetch()
      }, 1000)
    }
    folderPagination.refetch()
  }

  const moveToNewFolder = async (
    itemId: string,
    folder: string,
    orgId: string,
  ) => {
    try {
      if (type === 'audiences' && audienceServiceApi)
        await audienceServiceApi
          ?.advertisingPlatformServiceUpdateAudience(
            itemId,
            orgId,
            {
              folder,
            },
            'folder',
          )
          .then(() => resetAfterFolderEdit())

      if (type === 'creatives' && creativeServiceApi && orgId) {
        await creativeServiceApi
          ?.advertisingPlatformServiceUpdateCreative(
            itemId,
            orgId,
            {
              orgId,
              folder,
            },
            'folder',
          )
          .then(() => resetAfterFolderEdit())
      }
      addToFolderList(folder)
    } catch {
      showErrorMessage(
        'Error',
        `There was an error moving ${type.slice(
          0,
          -1,
        )} id ${itemId} to folder ${folder}.`,
      )
    }
  }

  const removeFromFolder = (item: ItemType, folder: string, orgId: string) => {
    if (item?.id) {
      try {
        if (type === 'audiences') {
          audienceServiceApi
            ?.advertisingPlatformServiceUpdateAudience(
              item.id,
              orgId,
              {
                folder: '',
              },
              'folder',
            )
            .then(() => resetAfterFolderEdit())
        }
        if (type === 'creatives') {
          creativeServiceApi
            ?.advertisingPlatformServiceUpdateCreative(
              item.id,
              orgId,
              {
                orgId,
                folder: '',
              },
              'folder',
            )
            .then(() => resetAfterFolderEdit())
        }
      } catch {
        showErrorMessage(
          'Error',
          `There was an error removing ${type.slice(0, -1)} id ${
            item.id
          } from folder ${folder}.`,
        )
      }
    }
  }

  const renameFolder = async (
    itemId: ItemType,
    newFolder: string,
    orgId: string,
  ) => {
    try {
      // fetches up to 1000 files
      const files = await handleFetchItems({
        pageSize: 100,
      })
      if (type === 'audiences') {
        // Update the folder property of each item to new folder name so the old folder will be removed from view
        if (files.totalItems > 0) {
          const updatePromises = (files.data as Targetjobservicev1Audience[])
            .filter((item) => item.folder === itemId.folder)
            .map((item) =>
              audienceServiceApi?.advertisingPlatformServiceUpdateAudience(
                item.id!,
                orgId,
                {
                  folder: newFolder,
                },
                'folder',
              ),
            )
          await Promise.all(updatePromises)
        }
      }

      if (type === 'creatives') {
        if (files.totalItems > 0) {
          const updatePromises = (files.data as Creativeservicev1Creative[])
            .filter((item) => item.folder === itemId.folder)
            .map((item) =>
              creativeServiceApi?.advertisingPlatformServiceUpdateCreative(
                item.id!,
                orgId,
                {
                  orgId,
                  folder: newFolder,
                },
                'folder',
              ),
            )
          await Promise.all(updatePromises)
        }
      }
      // cleanup
      await resetAfterFolderEdit()
      // refresh pagination view again to remove the stale view of old folder on paginated table
      await pagination.refetch()
    } catch {
      showErrorMessage(
        `Sorry, there's a problem...`,
        `When trying to rename your ${itemId.folder} folder to ${newFolder}. Please try again`,
      )
    }
  }

  const deleteFolder = async (
    item: ItemType,
    folder: string,
    orgId: string,
  ) => {
    // fetches all files, up to 1000 files
    const files = await handleFetchItems({
      pageSize: 100,
    })
    if (item.id) {
      try {
        if (type === 'audiences') {
          // Update the folder property of each item to an empty string so the folder will be removed from view
          if (files.totalItems > 0) {
            const updatePromises = (files.data as Targetjobservicev1Audience[])
              .filter((item) => item.folder === folder)
              .map((item) =>
                audienceServiceApi?.advertisingPlatformServiceUpdateAudience(
                  item.id!,
                  orgId,
                  {
                    folder: '',
                  },
                  'folder',
                ),
              )
            await Promise.all(updatePromises)
          }
        }

        if (type === 'creatives') {
          if (pagination.totalItems > 0) {
            const updatePromises = (files.data as Creativeservicev1Creative[])
              .filter((item) => item.folder === folder)
              .map((item) =>
                creativeServiceApi?.advertisingPlatformServiceUpdateCreative(
                  item.id!,
                  orgId,
                  {
                    orgId,
                    folder: '',
                  },
                  'folder',
                ),
              )
            await Promise.all(updatePromises)
          }
        }
        // cleanup
        await resetAfterFolderEdit()
        // refresh pagination view to remove the stale view of deleted folder
        await pagination.refetch()
      } catch {
        showErrorMessage(
          `Sorry, there's a problem...`,
          `When removing the folder, ${folder}. Please try again.`,
        )
      }
    }
  }

  // Handle table row click selection
  const handleRowClick = (clicked: ItemType) => {
    if (clicked.id === selectedItem?.id) {
      handleClearSelectedItem()
    } else {
      handleSetSelectedItem(clicked)
    }
  }

  const handleSort = (
    _: number,
    sort?: 'asc' | 'desc',
    path?: string | string[],
  ) =>
    !sort || !path
      ? setSort(undefined)
      : setSort({ field: path as string, direction: sort || 'asc' })

  const handleTableFilter = (
    _: number,
    path: string | string[],
    filterOn: FilterValueType,
  ) => {
    const key = typeof path === 'object' ? path.join('.') : path
    if (path === 'child_orgs' && setChildOrgsFilter) {
      setChildOrgsFilter(filterOn as string[])
      return
    }
    setTableFilter((prev) => {
      return { ...prev, [key]: filterOn }
    })
  }

  const handleGlobalFilter = (keys: string[], filterOn: string[]) => {
    const obj = keys.reduce((acc: { [key: string]: string[] }, key) => {
      acc[key] = filterOn
      return acc
    }, {})
    setGlobalFilter((prev) => ({ ...prev, ...obj }))
  }

  const handleSetSelectedItem = (item: ItemType) => {
    // Set item
    setSelectedItem(item)
    // Change URL
    if (isStandAlone) {
      navigate(
        `${baseUrl}/${item.id}${item.orgId ? `?org_id=${item.orgId}` : ''}`,
        { replace: true },
      )
    }
  }

  const handleClearSelectedItem = () => {
    setSelectedItem(undefined)
    if (isStandAlone)
      navigate(`${baseUrl}?org_id=${currentOrg?.id}`, { replace: true })
  }

  const contextValues = {
    currentData: pagination.currentPageData,
    currentFolderPath,
    setCurrentFolderPath,
    pagination,
    folderPagination,
    selectedItem,
    handleSetSelectedItem,
    handleClearSelectedItem,
    handleRowClick,
    moveToNewFolder,
    renameFolder,
    removeFromFolder,
    deleteFolder,
    folderList,
    sort,
    setSort,
    folderSort,
    setFolderSort,
    tableFilter,
    setTableFilter,
    handleSort,
    handleTableFilter,
    childOrgsOnly,
    setChildOrgsOnly,
    showArchived,
    setShowArchived,
    childOrgFilter,
    setChildOrgsFilter,
    handleGlobalFilter,
  }

  return (
    <LibraryContext.Provider value={contextValues}>
      {children}
    </LibraryContext.Provider>
  )
}
